<app-top-bar title="PAYMENT.PAY_CARD" enableClose="false" isDialog="true" (closed)="close()">
</app-top-bar>

<div class="content">
  <div class="payment-summary">
    <p class="name">{{ 'PAYMENT.SUMMARY' | translate }}</p>

    <div class="total">
      <span class="label">
        {{ 'CART.TOTAL' | translate }}
      </span>
      <span class="value">
        {{ data.total_price_without_discount | currencySpace }}
        <div class="default-currency">
          {{ data.total_price_without_discount | currencySpace:true }}
        </div>
      </span>
    </div>

    <div *ngIf="hasVoucher" class="total">
      <span class="label voucher-total">
        {{ 'VOUCHER.VOUCHER' | translate }}
      </span>
      <span class="value voucher-total">
        - {{ data.discount_amount | currencySpace }}
      </span>
    </div>

    <div *ngIf="hasVoucher" class="total">
      <span class="label">
        {{ 'VOUCHER.DISCOUNTED_AMOUNT' | translate }}
      </span>
      <span class="value">
        {{ data.total_price | currencySpace }}
      </span>
    </div>
    <hr>
  </div>

  <div class="payment-form">
    <form ngNoForm [action]="data.url" class="paymentWidgets" data-brands="VISA MASTER AMEX APPLEPAY">
    </form>
  </div>

</div>


