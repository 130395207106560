import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { RejectedItem } from '../cart/rejected-items/rejected-items.component';
import { ApiService, GetOrder, MenuClosedResponse, Order, Transaction } from './api.service';
import { DataService } from './data.service';
import { SplashScreenService } from './splash-screen.service';

export interface RejectedItemsErrorResponse {
  items: RejectedItem[];
  modifiers: RejectedItem[];
  message: string;
}

@Injectable({
  providedIn: 'root'
})
export class OrderService {

  private lastOrder: GetOrder;
  private lastTransaction: Transaction;

  constructor(
    private router: Router,
    private apiService: ApiService,
    private dataService: DataService,
    private splashScreenService: SplashScreenService
  ) { }
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  public submitOrder(tableId: string, order: Order): Promise<any> {
    // eslint-disable-next-line  @typescript-eslint/no-unused-vars
    return new Promise ((resolve, reject) => { 
      this.apiService.orderItems(tableId, order)
        .subscribe({
          next: (orderResponse) => resolve({order: orderResponse}),
          error: (error) => {
            if (error.status === 403) {
              const menuClosed: MenuClosedResponse = error.error;
              this.splashScreenService.showSplash(menuClosed.image, menuClosed.languages, true);
            } else {
              resolve({error: error.error})
            }
          }
        });
    });
  }

  public setLastOrder(order?: GetOrder): void {
    if (order) {
      this.lastOrder = order;
      this.router.navigate(['/confirm-order']);
    } else {
      const userId: string = this.dataService.getUserId();
      const restaurantId: string = this.dataService.getRestaurant().id;
      this.apiService.getOrders(userId, restaurantId).subscribe(orders => {
        this.lastOrder = orders.orders[0];
        this.router.navigate(['/confirm-order']);
      });
    }
  }

  public getLastOrder(): GetOrder {
    return this.lastOrder;
  }

  public setLastTransaction(transaction_id: string): void {
    this.apiService.getTransaction(transaction_id).subscribe(transaction => {
      this.lastTransaction = transaction;
      this.router.navigate(['/bill-paid']);
    });
  }

  public getLastTransaction(): Transaction {
    return this.lastTransaction;
  }
 }
