<app-top-bar></app-top-bar>

<div class="confirmation-container">
  <div class="top">
    <img [src]="'order_confirmed.svg' | themeSource" />
    <span class="text">{{ 'ORDER.PLACED' | translate }}</span>
    <span class="text small">{{ order.table.name }} | {{ order.created_at | date:'shortTime' }}</span>
    <span class="text">{{ 'ORDER.ORDER' | translate }} # {{ order.code.substr(4) }}</span>
  </div>

  <div class="order">
    <ul>
      <li *ngFor="let item of order.items">
        <div class="item-header">
          <span class="item-info">{{ item.quantity }} x {{ item.item }}</span>
          <span class="item-price">
            {{ item.total_price_without_discount | currencySpace }}
          </span>
        </div>
        <ul class="item-details" *ngIf="item.modifiers && item.modifiers.length > 0">
          <li *ngFor="let mod of item.modifiers">* <span *ngIf="mod.quantity > 1"> x{{ mod.quantity }} </span>{{ mod.modifier }} {{ mod.total_price | currencySpace }}</li>
        </ul>
        <ul class="item-details" *ngIf="item.comment">
          <li *ngIf="item.comment"> *{{item.comment}}</li>
        </ul>
      </li>
    </ul>
  </div>

  <div class="total" *ngIf="order.fee_amount > 0">
    <div class="row">
      <span class="label">{{ 'COMMON.FEES' | translate }}</span>
      <span class="amount">
        {{ order.fee_amount | currencySpace }}
        <div class="default-currency">
          {{ order.fee_amount | currencySpace:true }}
        </div>
      </span>
    </div>
  </div>

  <div class="total" *ngIf="order.discount_amount === 0">
    <div class="row">
      <span class="label">{{ 'ORDER.TOTAL' | translate }}</span>
      <span class="amount">
        {{ order.total_price | currencySpace }}
        <div class="default-currency">
          {{ order.total_price | currencySpace:true }}
        </div>
      </span>
    </div>
  </div>

  <div class="total" *ngIf="order.discount_amount > 0">
    <div class="row">
      <span class="label">{{ 'ORDER.TOTAL' | translate }}</span>
      <span class="amount">
        {{ order.total_price_without_discount | currencySpace }}
        <div class="default-currency">
          {{ order.total_price_without_discount | currencySpace:true }}
        </div>
      </span>
    </div>

    <div class="row voucher">
      <span class="label">{{ 'VOUCHER.VOUCHER' | translate }}</span>
      <span class="amount">
        -{{ order.discount_amount | currencySpace }}
        <div class="default-currency">
          -{{ order.discount_amount | currencySpace:true }}
        </div>
      </span>
    </div>
    <hr>
    <div class="row">
      <span class="label">{{ 'VOUCHER.DISCOUNTED_AMOUNT' | translate }}</span>
      <span class="amount">
        {{ order.total_price | currencySpace }}
        <div class="default-currency">
          {{ order.total_price | currencySpace:true }}
        </div>
      </span>
    </div>
  </div>

  <div class="comment" *ngIf="orderMessage">
    <span>{{orderMessage}}</span>
  </div>

  <div class="buttons">
    <div class="receipt" *ngIf="showReceiptButton">
      <span class="receipt-text" (click)="openReceipt()">{{ 'ACTIONS.VIEW_RECEIPT' | translate }}</span>
      <img [src]="'receipt.svg' | themeSource" (click)="openReceipt()">
    </div>
    <button mat-raised-button class="close" (click)="close()">
      {{ 'ACTIONS.AWESOME' | translate }}
    </button>
  </div>
</div>




