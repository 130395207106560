<div class="container">
  <p class="title">{{ message }}</p>

  <div class="buttons">
    <button mat-raised-button class="confirm" (click)="onConfirm()">
      {{ 'ACTIONS.CONFIRM' | translate }}
    </button>
    <button mat-raised-button class="cancel" (click)="onDismiss()">
      {{ 'ACTIONS.CANCEL' | translate }}
    </button>
  </div>
</div>


