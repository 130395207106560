import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LandingComponent } from './landing/landing.component';
import { BillComponent } from './bill/bill.component';
import { OrderConfirmationComponent } from './order-confirmation/order-confirmation.component';
import { BillPaidComponent } from './bill-paid/bill-paid.component';
import { LanguageSelectionComponent } from './language-selection/language-selection.component';
import { SuperSectionsContainerComponent } from './menu/super-sections-container/super-sections-container.component';

const appRoutes: Routes = [

  { path: '', component: LandingComponent },
  { path: 'menu', component: SuperSectionsContainerComponent },
  { path: 'bill', component: BillComponent },
  { path: 'confirm-order', component: OrderConfirmationComponent },
  { path: 'bill-paid', component: BillPaidComponent },
  { path:'select-language', component: LanguageSelectionComponent},

  // lazy loaded modules
  { path: 'rescan', loadChildren: () => import('./qrscan/qrscan.module').then(m => m.QRScanModule) },
  { path: 'order-form', loadChildren: () => import('./order-form/order-form.module').then(m => m.OrderFormModule) }
];

@NgModule({
  declarations: [],
  imports: [
    RouterModule.forRoot(
      appRoutes, {
        scrollPositionRestoration: 'enabled',
        anchorScrolling: 'enabled',
      }
    ),
  ],
  exports: [
    RouterModule
  ]
})
export class AppRoutingModule { }
