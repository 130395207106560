<mat-progress-bar mode="indeterminate" color="accent" *ngIf="isLoading"></mat-progress-bar>
<div class="payment-method">
  <h5>
    {{ 'BILL.PAYMENT_OPTIONS' | translate }}
  </h5>

  <img class="close" [src]="'close-black.svg' | themeSource" alt="close" height="14px" (click)="close()"/>

  <mat-radio-group [(ngModel)]="selectedPaymentType" (change)="setPaymentType()">
    <ng-container *ngFor="let paymentType of paymentTypes">
      <mat-radio-button [value]="paymentType">
        <img [src]="paymentType.key | paymentTypes | themeSource" alt="{{paymentType.name}}" />
        <span>{{ paymentType.name }}</span>
      </mat-radio-button>
    </ng-container>
  </mat-radio-group>

  <button *ngIf="!isApplePay" class="pay-button" mat-raised-button [disabled]="submitted" (click)="done()">
    <span *ngIf="!selectedPaymentType.is_online">
      {{ 'CART.PLACE_ORDER' | translate }}
    </span>
    <span *ngIf="selectedPaymentType.is_online">
      {{ 'PAYMENT.PAY_CARD' | translate }}
    </span>
  </button>

  <div *ngIf="isApplePay" id="ckoApplePay" class="apple-pay-button" lang=en (click)="submitApplePay()"></div>

</div>
