<div class="payment-method">
  <h5>
    {{ 'BILL.PAYMENT_OPTIONS' | translate }}
  </h5>

  <img class="close" [src]="'close-black.svg' | themeSource" alt="close" height="14px" (click)="done()" />

  <mat-radio-group [(ngModel)]="paymentType" (change)="done()">
    <ng-container *ngFor="let paymentMethod of paymentMethods">
      <mat-radio-button value="{{paymentMethod.key}}">
        <img [src]="paymentMethod.key | paymentTypes | themeSource" alt="{{paymentMethod.name}}" />
        <span>{{ paymentMethod.name }}</span>
      </mat-radio-button>
    </ng-container>
  </mat-radio-group>

</div>
