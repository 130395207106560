<div *ngIf="currentSection">
  <ul class="subsection-filters scrollable" *ngIf="currentSection.sub_sections?.length > 1">
    <ng-container *ngFor="let section of currentSection.sub_sections">
      <li *ngIf="section.isActive" id="item-{{section.name}}" [ngClass]="{'selected': section.name === activeSubsection}"
          (click)="scrollVertical(section.name)">
        {{ section.name }}
      </li>
    </ng-container>
  </ul>

  <div [@slideInOut]="animationState" class="cart-preview" [ngClass]="{'search-opened': searchOpened || currentSection.sub_sections?.length > 1}" (click)="openCart()">
    <div class="cart">
      <img [src]="'cart.svg' | themeSource" alt="cart"/>
      <span class="items">{{quantity}}</span>
    </div>
    <div class="text">
      <span class="order">{{ 'CART.VIEW' | translate }}</span>
    </div>
    <div class="text-price">
      <span class="price">{{ totalPrice | currencySpace }}</span>
    </div>
  </div>

  <app-section *ngIf="!hasSubsections" id="{{currentSection?.name}}" [section]="currentSection"></app-section>

  <div *ngIf="hasSubsections" appScrollSpy [spiedTags]="['APP-SECTION']" (sectionChange)="onSectionChange($event)" [ngClass]="{'padded': currentSection.sub_sections?.length > 1}">
    <p *ngIf="currentSection?.description && currentSection?.sub_sections.length > 0" class="section-description">{{currentSection.description}}</p>
    <ng-container *ngFor="let section of currentSection.sub_sections">
      <app-section *ngIf="section.isActive" id="{{section.name}}" [section]="section"></app-section>
    </ng-container>
  </div>
</div>
