/* eslint-disable  @typescript-eslint/no-explicit-any */
import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpRequest, HttpHandler } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { TranslateService } from '@ngx-translate/core';

export enum RequestStatus {
  Pending,
  Success,
  InvalidRequest,
  ServerError
}

@Injectable()
export class ApiInterceptor implements HttpInterceptor {

  private readonly TTL = 60 * 60 * 0.5; // 30 min

  constructor(
    private translateService: TranslateService,
  ) { }

  public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // Skipping the translation files
    if (request.url.match(/.*\.json/)) {
      return next.handle(request);
    }

    return this.doRequest(request, next);
  }

  private doRequest(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (this.isOPaalaApi(request)) {
      const headers = {
        'X-LANGUAGE': this.translateService.currentLang
      };
      request = request.clone({setHeaders: headers});
    }
    return next.handle(request);
  }

  private isOPaalaApi(request: HttpRequest<any>): boolean {
    return request.url.indexOf(environment.urls.api) >= 0;
  }

}
