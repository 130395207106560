import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, ReplaySubject } from 'rxjs';
import { ApiService, ItemFilter } from './api.service';
import { DataService } from './data.service';

export interface ActivableFilter extends ItemFilter {
  isActive: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class FiltersService {

  public allFilters$ = new BehaviorSubject<ActivableFilter[]>([]);
  public activeFilters$ = new ReplaySubject<string[]>(1);
  public isActive$ = new BehaviorSubject<boolean>(false);

  private lang: string;

  constructor(
    private apiService: ApiService,
    private dataService: DataService,
    private translateService: TranslateService
  ) { }

  public setFilters(): void {
    if (this.allFilters$.value.length > 0 && this.lang === this.translateService.currentLang) {
      return;
    }
    this.apiService.getItemFilters(this.dataService.getRestaurant().id)
      .subscribe(response => {
      const filters = response.map((filter: ItemFilter) => this.makeActivableFilter(filter));
      this.allFilters$.next(filters);
    });
    this.lang = this.translateService.currentLang;
  }

  public updateFilters(filters: ActivableFilter[]): void {
    this.allFilters$.next(filters);
  }

  public setActiveFilters(): void {
    const activeFilters: string[] = [];
    this.allFilters$.subscribe(filters => {
      filters.forEach(filter => {
        if (filter.isActive) {
          activeFilters.push(filter.id);
        }
      });
      if (activeFilters.length > 0) {
        this.isActive$.next(true);
      } else {
        this.isActive$.next(false);
      }
      this.activeFilters$.next(activeFilters);
    });
  }

  private makeActivableFilter(filter: ItemFilter): ActivableFilter {
    return {
      id: filter.id,
      key: filter.key,
      name: filter.name,
      lang: filter.lang,
      isActive: false
    };
  }
}
