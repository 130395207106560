import { Injectable } from '@angular/core';
import { v4 as uuidv4 } from 'uuid';

@Injectable({
  providedIn: 'root'
})
export class UserIdService {

  private tutorialDone: boolean;

  public getUserId(): string {
    return localStorage.getItem('userId') || '';
  }

  public setUserId(): void {
    localStorage.setItem('userId', uuidv4());
  }
}
