import { Injectable, Pipe, PipeTransform } from '@angular/core';
import { environment } from '../../../environments/environment';
import { DomainService } from '../../services/domain.service';

@Pipe({
  name: 'themeSource'
})
@Injectable({
  providedIn: 'root'
})
export class ThemeSourcePipe implements PipeTransform {

  private subDomain: string;
  private assetsURL: string;

  constructor(
    private domainService: DomainService
  ) {
    this.subDomain = domainService.getSubdomain() === 'c' ? 'default' : domainService.getSubdomain();
    this.assetsURL = environment.urls.assets;
   }

  transform(imgName: string): string {
    return `${this.assetsURL}/${this.subDomain}/${imgName}`;
  }

}
