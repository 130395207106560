<app-top-bar title="MENU.SETTINGS" enableClose="false" isDialog="true" (closed)="close()"></app-top-bar>

<div class="content">
  <div class="settings">
    <div class="panel" hideToggle="true">
      <div class="subtitle">
        {{ 'SETTINGS.LANGUAGE' | translate }}
      </div>
      <mat-radio-group class="language" [(ngModel)]="currentLang" (change)="updateLang()">
        <div class="lang" *ngFor="let lang of languages">
          <mat-radio-button [value]="lang.key"></mat-radio-button>
          <span class="label">{{ lang.label | translate }}</span>
        </div>
      </mat-radio-group>
    </div>
    <div class="panel" *ngIf="currencies?.length > 0">
      <div class="subtitle">
        {{ 'SETTINGS.CURRENCY' | translate }}
      </div>
      <mat-select class="bordered" [(value)]="selectedCurrency" (valueChange)="updateCurrency()">
        <mat-option *ngFor="let currency of currencies" [value]="currency">{{currency}}</mat-option>
      </mat-select>
    </div>
    <button
      mat-raised-button
      class="confirm"
      (click)="confirm()"
      [disabled]="confirmDisabled"
    >
      {{ 'ACTIONS.CONFIRM' | translate }}
    </button>
  </div>
</div>

