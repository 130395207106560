/* eslint-disable  @typescript-eslint/no-explicit-any */
import { Component, OnInit, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { CartService} from '../services/cart.service';
import { Modifier, OrderItem, Order,
         ApiService, PickupFormField, OrderForm, Fee,
         PaymentMode, PaymentMethod, AppliedVoucher, VoucherPayload, VoucherMode, VenueType } from '../services/api.service';
import { FormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';
import { DataService } from '../services/data.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { MenuService } from '../menu/menu.service';
import { ApplePayService } from '../services/apple-pay.service';
import { VoucherModalComponent } from './voucher-modal/voucher-modal.component';
import { OnlinePaymentService } from '../services/online-payment.service';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { PaymentSheetComponent } from './payment-sheet/payment-sheet.component';
import { OrderService, RejectedItemsErrorResponse } from '../services/order.service';
import { UserIdService } from '../services/userid.service';
import { RejectedItem, RejectedItemsComponent } from './rejected-items/rejected-items.component';
import { ScheduleService } from '../services/schedule.service';
import { ConfirmationDialogComponent } from '../confirmation-dialog/confirmation-dialog.component';
import { CommentDialogComponent } from './comment-dialog/comment-dialog.component';
import { DateTime } from 'luxon';

interface Line {
  id: string;
  itemName: string;
  itemId: string;
  modifiers: Modifier[];
  quantity: number;
  comment?: string;
  total: number;
}

export interface ApplePayConfig {
  countryCode: string;
  currencyCode: string;
  supportedNetworks: string[];
  merchantCapabilities: string[];
  total: {
    label: string;
    amount: number;
  };
}

@Component({
  selector: 'app-cart',
  templateUrl: './cart.component.html',
  styleUrls: ['./cart.component.scss']
})
export class CartComponent implements OnInit, OnDestroy {

  public totalQuantity: number;
  public lines: Line[] = [];
  public submitted = false;
  public expanded: boolean;
  public form: FormGroup;
  public orderMessage: string;
  public isApplePayAvailable = false;
  public hasApplePay = false;
  public currency: string;
  public paymentMode: PaymentMode;
  public PaymentMode = PaymentMode;
  public VoucherMode = VoucherMode;
  public formSubmitted = true;
  public voucherSubmitted = true;
  public Math: Math;
  public isOrderFree = false;
  public appliedVoucher: AppliedVoucher;
  public paymentType: PaymentMethod;
  public isLoading = false;
  public orderNote: string;
  public fees: Fee[];
  public minOrder: number;

  public subtotal: number;
  public finalTotal: number;
  public discountAmount: number;
  public feesAmount: number;

  private voucherName: string;
  private orderId: string;
  private restaurantId: string;
  private formData: any;
  private orderForm: OrderForm;
  private paymentTypes: PaymentMethod[];
  private tableId: string;

  private cartSub = Subscription.EMPTY;
  private apiSub = Subscription.EMPTY;
  private applePaySub = Subscription.EMPTY;
  private paymentCancelledSub = Subscription.EMPTY;
  private configSub = Subscription.EMPTY;
  private voucherSub = Subscription.EMPTY;

  constructor(
    private dialog: MatDialog,
    private dataService: DataService,
    private changeDetectorRef: ChangeDetectorRef,
    private cartService: CartService,
    private snackBar: MatSnackBar,
    private apiService: ApiService,
    private translateService: TranslateService,
    private menuService: MenuService,
    public dialogRef: MatDialogRef<CartComponent>,
    private applePayService: ApplePayService,
    private onlinePaymentService: OnlinePaymentService,
    private bottomSheet: MatBottomSheet,
    private orderService: OrderService,
    private userIdService: UserIdService,
    private scheduleService: ScheduleService,
  ) {
    this.Math = Math;
    this.tableId = this.dataService.getTable().id;
  }

  public ngOnInit(): void {
    this.submitted = true;

    // This is the Additional Comments form
    this.form = new FormGroup({});

    this.restaurantId = this.dataService.getRestaurant().id;
    this.paymentMode = this.dataService.getRestaurant().payment_mode;

    this.configSub = this.apiService.getCartConfig(this.restaurantId).subscribe(res => {
      // This is the Order Details form (# guests, payment option...)
      if (res.form) {
        this.orderForm = res.form;
        this.checkForm();
      }
      this.minOrder = res.min_order;
      this.submitted = false;
    });

    // handling payment methods
    const ezetapMachineId = this.dataService.getEzetapMachineId();
    const methods = this.dataService.getPaymentMethods();
    if (methods?.length > 0) {
      this.paymentTypes = methods;
      if (ezetapMachineId) {
        this.paymentTypes = methods.filter((type) => type.key === 'ezetap');
      } else {
        this.paymentTypes = methods.filter((type) => type.key !== 'ezetap');
        this.isApplePayAvailable = this.applePayService.isApplePayAvailable();
        if (this.isApplePayAvailable) { // check if supported by browser
          const paymentMethods = methods.map(method => method.key);
          if (paymentMethods.includes('applepay')) {
            this.hasApplePay = true;
          }
        } else { // remove apple pay from available the payment options array if its not supported by browser
          this.paymentTypes = this.paymentTypes.filter((type) => type.key !== 'applepay');
        }
      }
    }

    this.updateView();

    if (this.paymentMode === PaymentMode.Prepaid) {
      // If we have a saved voucher (i.e wallet)
      const voucherName = this.dataService.getVoucherName();
      this.voucherSubmitted = false;
      this.applyCheckout(voucherName);
    }

    this.currency = this.dataService.getRestaurant().currency;
    this.cartSub = this.cartService.cartUpdated.subscribe(() => this.updateView());
    this.menuService.orderMessage$
      .subscribe(message => {
        this.orderMessage = message;
    });

    // un-disable the submit button in case the payment was cancelled.
    this.paymentCancelledSub = this.onlinePaymentService.paymentCancelled.subscribe(cancelled => {
      if (cancelled) {
        this.submitted = false;
        this.isLoading = false;
        if (this.orderId) {
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          this.apiService.cancelOrder(this.orderId).subscribe(ok => this.orderId = '');
        }
        this.changeDetectorRef.detectChanges();
      }
    });
  }

  public ngOnDestroy(): void {
    this.cartSub.unsubscribe();
    this.apiSub.unsubscribe();
    this.applePaySub.unsubscribe();
    this.paymentCancelledSub.unsubscribe();
    this.configSub.unsubscribe();
    this.voucherSub.unsubscribe();
  }

  public isReadOnly(): boolean {
    const type: VenueType = this.dataService.getRestaurant().type;
    return type === VenueType.ReadOnly;
  }

  public increase(line: Line): void {
    this.cartService.incrementLine(line.id);
    this.checkForm();
    this.applyCheckout(this.voucherName);
  }

  public decrease(line: Line): void {
    this.cartService.decrementLine(line.id);
    this.checkForm();
    this.applyCheckout(this.voucherName);
  }

  public submit(): void {
    const paymentMode = this.dataService.getRestaurant().payment_mode;
    const currentLang = this.translateService.currentLang;
    if (!this.formSubmitted) {
      this.openOrderForm(this.orderForm.content[currentLang]);
      return;
    }
    if (paymentMode === PaymentMode.Prepaid) {
      this.submitDirectPayment();
    } else {
      this.submitRestaurant();
    }
  }

  public openPaymentSheet(order: Order): void {
    const applepayConfig = this.getApplePayConfig();
    const bottomSheetRef = this.bottomSheet.open(PaymentSheetComponent, {
      panelClass: 'payment-method-container',
      data: {
        paymentType: this.paymentType,
        paymentTypes: this.paymentTypes,
        applepayConfig,
        order
      },
    });

    bottomSheetRef.afterDismissed().subscribe(data => {
      if (!data) {
        this.submitted = false;
        this.checkForm();
        return;
      }

      if (data.error) {
        this.handleRejectedItems(data.error);
        return;
      }

      if (data.paymentType.key === 'applepay' || !data.paymentType.is_online) {
        this.cartService.clearCart();
        this.orderService.setLastOrder(data.order);
        this.dialogRef.close();
      }
    });
  }

  public close(): void {
    this.dialogRef.close();
  }

  public clearCart(): void {
    const message = this.translateService.instant('CART.CONFIRM_CLEAR');
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      disableClose: false,
      height: '250px',
      width: '300px',
      data: message
    });

    dialogRef.afterClosed().subscribe(dialogResult => {
      if (dialogResult) {
        this.cartService.clearCart();
      }
    });
  }

  public openVoucherModal(): void {
    const dialogRef = this.dialog.open(VoucherModalComponent, {
      width: '350px',
    });

    dialogRef.afterClosed().subscribe(data => {
      if (data) {
        this.applyCheckout(data.voucherName);
      }
    });
  }

  public removeLine(line: Line): void {
    this.cartService.removeLine(line.id);
    this.checkForm();
    this.applyCheckout(this.voucherName);
  }

  public addItemComment(line?: Line): void {
    const dialogRef = this.dialog.open(CommentDialogComponent, {
      width: '350px',
      data: {
        line,
        orderNote: this.orderNote
      }
    });

    dialogRef.afterClosed().subscribe(data => {
      if (!data) {
        return;
      }
      if (line) {
        this.cartService.addItemComment(line.id, data.comment);
        this.applyCheckout(this.voucherName);
      } else {
        this.orderNote = data.comment;
      }
    });
  }

  public showMinOrderMessage(): boolean {
    if (this.minOrder > 0 && this.subtotal < this.minOrder && this.paymentMode === PaymentMode.Prepaid) {
      return true;
    } else {
      return false;
    }
  }

  private getApplePayConfig(): ApplePayConfig {
    const total = this.finalTotal;
    return {
      countryCode: this.currency === 'SAR' ? 'SA' : 'AE',
      currencyCode: this.currency,
      supportedNetworks: ['visa', 'masterCard', 'amex', 'discover', 'mada'],
      merchantCapabilities: ['supports3DS'],
      total: {
        label: 'Opaala',
        amount: total
      }
    };
  }

  private submitDirectPayment(): void {
    this.paymentType = this.paymentTypes[0];

    if (this.isOrderFree) {
      this.submitRestaurant();
      return;
    }

    if (this.subtotal < this.minOrder) {
      this.snackBar.open(
        this.translateService.instant('COMMON.MIN_ORDER_ERROR', {currency: this.currency, amount: this.minOrder}),
        this.translateService.instant('CART.GOT_IT'),
        {
          duration: 3500,
          panelClass: 'custom-snackbar'
        }
      );
      return;
    }

    this.submitted = true;
    const order = this.makeOrder(this.formData);
    if (this.paymentTypes.length > 1) {
      this.openPaymentSheet(order);
    } else {
      if (!this.paymentType.is_online) {
        this.snackBar.open(
          'Unable to process payment type',
          this.translateService.instant('CART.GOT_IT'),
          {
            duration: 3500,
            panelClass: 'custom-snackbar'
          }
        );
        return;
      }
      this.isLoading = true;
      this.orderService.submitOrder(this.tableId, order).then(res =>{
        if (res.order) {
          this.orderId = res.order.order_id;
          const orderIds = [res.order.order_id];
          this.onlinePaymentService.startPayment(orderIds);
        } else if (res.error?.modifers || res.error?.items) {
          this.handleRejectedItems(res.error);
        }
      });
    }
  }

  private applyCheckout(voucherName?: string): void {
    if (this.totalQuantity <= 0 || this.paymentMode !== PaymentMode.Prepaid) {
      return;
    }

    if (voucherName) {
      this.voucherSubmitted = true;
    }

    const voucherPayload: VoucherPayload = {
      user_id: this.userIdService.getUserId(),
      order_items: this.getOrderItems(),
      table_id: this.tableId,
      voucher_name: voucherName
    };

    this.apiService.applyPrepaidCheckout(this.restaurantId, voucherPayload)
      .subscribe(res => {
        this.isOrderFree = false;
        this.voucherName = voucherName || '';
        this.appliedVoucher = res.voucher;
        this.fees = res.orders[0].fees || [];
        this.subtotal = res.orders[0].total_price_without_discount;
        this.finalTotal = res.orders[0].total_price;

        if (this.appliedVoucher.mode === VoucherMode.Balance) {
          this.dataService.setVoucherName(this.voucherName);
        }

        if (this.finalTotal <= 0) {
          this.isOrderFree = true;
        }
      },(error) => {
        this.voucherSubmitted = false;
        this.snackBar.open(
          this.translateService.instant(error.error.message),
          this.translateService.instant('CART.GOT_IT'),
          {
            duration: 3500,
            panelClass: 'custom-snackbar'
          }
        );
      });
  }

  private async openOrderForm(form: PickupFormField[]) {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const { OrderFormComponent } = await import(
      /* webpackPrefetch: true */
      '../order-form/order-form/order-form.component'
    );
    const dialogRef = this.dialog.open(OrderFormComponent, {
      width: '350px',
      data: {
        form
      }
    });

    dialogRef.afterClosed().subscribe(data => {
      if (!data) {
        return;
      } else {
        if (data.metadata.date) {
          const date = DateTime.fromJSDate(new Date(data.metadata.date));
          data.metadata.date = date.toISODate();
        }
        this.formSubmitted = true;
        this.formData = data.metadata;
        this.submit();
      }
    });
  }

  private submitRestaurant(): void {
    this.submitted = true;
    const order = this.makeOrder(this.formData);

    this.orderService.submitOrder(this.tableId, order).then(res =>{
      if (res.order) {
        this.cartService.clearCart();
        this.orderService.setLastOrder(res.order);
        this.dialogRef.close();
      } else if (res.error?.modifers || res.error?.items) {
        this.handleRejectedItems(res.error);
      }
    });
  }

  private makeOrder(metadata?: any): Order {
    if (metadata && this.form.value.email) {
      metadata.email = this.form.value.email;
    } else if (this.form.value.email) {
      metadata = {
        email: this.form.value.email
      };
    }
    const userId: string = this.dataService.getUserId();
    const orderItems: OrderItem[] = this.getOrderItems();
    const order: Order = {
        order_items: orderItems,
        user_id: userId,
        comment: this.orderNote,
        metadata,
        voucher_name: this.voucherName || '',
        payment_type: this.paymentType?.key
    };

    return order;
  }

  private updateView(): void {
    this.totalQuantity = this.cartService.getCart().cartLines.reduce((accumulator, cartLine) => accumulator + cartLine.quantity, 0);

    this.lines = this.cartService.getCart().cartLines.map(cartLine => {
      const priceModifiers: number = cartLine.modifiers.reduce((acc, modifier) => acc + modifier.price, 0);

      return {
        id: cartLine.id,
        itemId: cartLine.item.id,
        itemName: cartLine.item.name,
        quantity: cartLine.quantity,
        modifiers: cartLine.modifiers,
        comment: cartLine.comment,
        total: (cartLine.item.price + priceModifiers) * cartLine.quantity,
      };
    });


    this.subtotal = this.lines.reduce((accumulator, cartLine) => accumulator + cartLine.total, 0);

    if (this.paymentMode === PaymentMode.Postpaid) {
      this.finalTotal = this.subtotal;
    }

    this.changeDetectorRef.markForCheck();
  }

  // Some forms should only appear when a requirement is met (min order)
  private checkForm(): void {
    if (!this.orderForm) {
      return;
    }
    if (!this.orderForm.metadata || (this.orderForm.metadata && this.subtotal > this.orderForm.metadata['min_order'])) {
      this.formSubmitted = false;
    } else {
      this.formSubmitted = true;
    }
  }

  private getOrderItems(): OrderItem[] {
    return this.cartService.getCart().cartLines.map(cartLine => ({
        id: cartLine.id,
        item: cartLine.item.id,
        comment: cartLine.comment || '',
        quantity: cartLine.quantity,
        modifiers: cartLine.modifiers.map(modifier => ({
          id: modifier.id,
          quantity: modifier.quantity
        }))
      }));
  }

  private updateMenu(): void {
    const zone = this.dataService.getZone();
    this.apiService
    .getMenuCached(zone.id)
    .subscribe({
      next: (responseMenu) => this.scheduleService.registerSchedules(responseMenu),
      error: () => {
        // If there is an error, we try again
        this.apiService.getMenuCached(zone.id).subscribe(responseMenu => {
          this.scheduleService.registerSchedules(responseMenu);
        });
      }
    });
  }

  private removeItems(items: RejectedItem[]): void {
    const lines = this.lines
      .filter(line => items
        .some(item => line.itemId === item.id));

    lines.forEach(line => this.removeLine(line));
  }

  private removeItemsByModifierIds(modifiers: RejectedItem[]): void {
    // find common elements between rejected modifiers and line modifiers
    this.lines.forEach(line => {
      const itemModifiers = line.modifiers
        .filter(mod => modifiers
          .some(modifier => mod.id === modifier.id));

      // remove item if its modifiers are rejected
      if (itemModifiers.length > 0) {
        this.removeLine(line);
      }
    });
  }

  private handleRejectedItems(rejectedItems: RejectedItemsErrorResponse) {
    this.updateMenu();

    if (rejectedItems.items?.length > 0) {
      this.removeItems(rejectedItems.items);
    }
    if (rejectedItems.modifiers?.length > 0) {
      this.removeItemsByModifierIds(rejectedItems.modifiers);
    }

    const dialogRef = this.dialog.open(RejectedItemsComponent, {
      width: '350px',
      data: rejectedItems
    });
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    dialogRef.afterClosed().subscribe(ok => this.submitted = false);
  }
}
