import { Injectable, EventEmitter } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SplashScreenService {
  public splashEmitter: EventEmitter<
  {
    image_url: string,
    languages?: string[],
    hide?: boolean
  }
  > = new EventEmitter();
  public loadedEmitter: EventEmitter<boolean> = new EventEmitter();
  public disabledEmitter: EventEmitter<boolean> = new EventEmitter();

  public showSplash(image_url: string, languages?: string[], hide?: boolean): void {
    this.splashEmitter.emit({image_url, languages, hide});
  }

  // This is to hide the splash screen after loading the data
  public hideSplash(): void {
    this.loadedEmitter.emit(true);
  }

  // This is to disable the splash screen from showing at all
  public disableSplash(): void {
    this.disabledEmitter.emit(true);
  }
}
