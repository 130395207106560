/* eslint-disable  @typescript-eslint/no-explicit-any */
import { Component, OnInit, Input } from '@angular/core';
import { GetOrder, GetOrderItem, OrderStatus, PaymentMode } from '../services/api.service';
import { DataService } from '../services/data.service';

export interface DisplayableOrder {
  section: string;
  order_details: GetOrder;
  order_items: GetOrderItem;
}

@Component({
  selector: 'app-order-list',
  templateUrl: './order-list.component.html',
  styleUrls: ['./order-list.component.scss']
})
export class OrderListComponent implements OnInit {

  @Input() orders: GetOrder[] = [];

  public paymentMode: PaymentMode;
  public PaymentMode = PaymentMode;
  public OrderStatus = OrderStatus;
  public displayableOrders: DisplayableOrder[];
  public isParent: boolean;

  constructor(
    private dataService: DataService
  ) { }

  public ngOnInit(): void {
    this.paymentMode = this.dataService.getRestaurant()?.payment_mode;

    if (!this.orders) {
      this.orders = [];
    }
    this.isParent = this.dataService.getIsParent();
    this.displayableOrders = this.makeOrdersDisplayable(this.orders);
  }

  /**
   * created_at and updated_at is handled automatically by django, meaning
   * that we do not have access to those fields and there are a few milliseconds
   * difference between them, even though the model is created at the same time.
   * so we need to compare the dates without the milliseconds.
   */
  public isDifferent(createdAt: string, updatedAt: string): boolean {
    const createdDate = new Date(createdAt);
    createdDate.setMilliseconds(0);

    const updatedDate = new Date(updatedAt);
    updatedDate.setMilliseconds(0);

    return createdDate.getTime() !== updatedDate.getTime();
  }

  public showPaid(order: DisplayableOrder): boolean {
    if (this.paymentMode === PaymentMode.Postpaid && order.order_details.status === OrderStatus.Paid) {
      return true;
    } else if (this.paymentMode === PaymentMode.Prepaid && order.order_details.status === OrderStatus.Paid
        && order.order_details.payment_type.is_online) {
      return true;
    }
    return false;
  }

  public showConfirmed(order: DisplayableOrder): boolean {
    const isDifferent = this.isDifferent(order.order_details.created_at, order.order_details.updated_at);
    if (isDifferent && this.paymentMode !== PaymentMode.Prepaid && order.order_details.status !== OrderStatus.Paid) {
      return true;
    } else if (isDifferent && this.paymentMode === PaymentMode.Prepaid && !order.order_details.payment_type.is_online) {
      return true;
    } else {
      return false;
    }
  }

  private splitOrderItemsByVenue(order: GetOrder): GetOrderItem[] {
    let val;
    let index;
    const items = order.items;
    const values: any[] = [];
    const result: any[] = [];

    // eslint-disable-next-line @typescript-eslint/prefer-for-of
    for (let i = 0; i < items.length; i++) {
      val = items[i].restaurant.id;
      index = values.indexOf(val);
      if (index > -1) {
        result[index].push(items[i]);
      } else {
        values.push(val);
        result.push([items[i]]);
      }
    }
    return result;
  }

  private groupOrdersByDate(orders: DisplayableOrder[]): DisplayableOrder[] {
    const result = orders.reduce((o, a) => {
      o[a.order_details.created_at] = o[a.order_details.created_at] || [];
      o[a.order_details.created_at].push(a);
      return o;
    }, Object.create(null));
    return result;
  }

  private makeOrdersDisplayable(orders: GetOrder[]): DisplayableOrder[] {
    let displayableOrders: DisplayableOrder[] = [];
    orders.forEach(order => {
      const groupedOrderItems = this.splitOrderItemsByVenue(order);
      groupedOrderItems.forEach(items => {
        const displayableOrder: DisplayableOrder = {
          order_items: items,
          order_details: order,
          section: items[0].restaurant.name
        };
        displayableOrders.push(displayableOrder);
      });
    });
    displayableOrders = this.groupOrdersByDate(displayableOrders);
    displayableOrders = this.reverseArr(displayableOrders);
    return displayableOrders;
  }

  private reverseArr(input) {
    const keys = Object.keys(input);
    const reversed: any[] = [];
    for(let i = keys.length-1; i >= 0; i--) {
      reversed.push(input[keys[i]]);
    }
    return reversed.reverse();
  }

}
