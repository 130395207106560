import { Component, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-font-mali',
  templateUrl: './mali.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./mali.component.scss']
})
export class FontMaliComponent {

  constructor() {
    document.body.style.setProperty('font-family', 'Krub, cursive');
  }

}
