import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { OnlinePaymentService } from '../services/online-payment.service';

@Component({
  selector: 'app-total-processing',
  templateUrl: './total-processing.component.html',
  styleUrls: ['./total-processing.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class TotalProcessingComponent implements OnInit {

  public hasVoucher = false;

  constructor(
    public dialogRef: MatDialogRef<TotalProcessingComponent>,
    // eslint-disable-next-line  @typescript-eslint/no-explicit-any
    @Inject(MAT_DIALOG_DATA)  public data: any,
    private onlinePaymentService: OnlinePaymentService
  ) { }

  public ngOnInit(): void {
    if (this.data.discount_amount > 0 ) {
      this.hasVoucher = true;
    }
  }

  public close(): void {
    this.dialogRef.close();
    this.onlinePaymentService.paymentCancelled.emit(true);
  }

}
