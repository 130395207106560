import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filter',
  pure: false
})
export class FilterPipe implements PipeTransform {
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  public transform(items: any[]): any {
    if (!items) {
      return items;
    }
    return items.filter(item => item.isActive);
  }

}
