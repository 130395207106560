import { Injectable, EventEmitter } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Promotion } from '../menu/promotions/promotions.component';
import { Table, Restaurant, Zone, PaymentMethod, MenuActionObject } from './api.service';

@Injectable({
  providedIn: 'root'
})
export class DataService {

  public restaurantEmitter: EventEmitter<Restaurant> = new EventEmitter();
  public zoneEmitter: EventEmitter<Zone> = new EventEmitter();
  public tableEmitter: EventEmitter<Table> = new EventEmitter();

  public hasMultiLanguage$ = new BehaviorSubject<boolean>(false);
  public hasMultiCurrency$ = new BehaviorSubject<boolean>(false);
  public hasFilters$ = new BehaviorSubject<boolean>(false);
  public isGridLayout$ = new BehaviorSubject<boolean>(false);
  public hasFees$ = new BehaviorSubject<boolean>(false);
  public logoUrl$ = new BehaviorSubject<string>('');

  private table: Table;
  private restaurant: Restaurant;
  private zone: Zone;
  private userId: string;
  private menuActions: MenuActionObject[];
  private voucherName: string;
  private ezetapMachineId: string;
  private paymentMethods: PaymentMethod[];
  private promotions: Promotion[];

  public setTable(table: Table): void {
    this.table = table;
    this.tableEmitter.emit(table);
  }

  public getTable(): Table {
    return this.table;
  }

  public setRestaurant(restaurant: Restaurant): void {
    this.restaurant = restaurant;
    if (restaurant.logo_url) {
      this.logoUrl$.next(restaurant.logo_url);
    }
    this.restaurantEmitter.emit(restaurant);
  }

  public getRestaurant(): Restaurant {
    return this.restaurant;
  }

  public getIsParent(): boolean {
    return this.restaurant?.is_parent;
  }

  public setZone(zone: Zone): void {
    this.zone = zone;
    this.zoneEmitter.emit(zone);
  }

  public getZone(): Zone {
    return this.zone;
  }

  public setUserId(userId: string): void {
    this.userId = userId;
  }

  public getUserId(): string {
    return this.userId;
  }

  public setMenuActions(actions: MenuActionObject[]): void {
    this.menuActions = actions;
  }

  public getMenuActions(): MenuActionObject[] {
    return this.menuActions;
  }

  public setPromotions(promotions: Promotion[]): void {
    this.promotions = promotions;
  }

  public getPromotions(): Promotion[] {
    return this.promotions;
  }

  public setPaymentMethods(methods: PaymentMethod[]): void {
    this.paymentMethods = methods;
  }

  public getPaymentMethods(): PaymentMethod[] {
    return this.paymentMethods;
  }

  public setVoucherName(voucherName: string): void {
    this.voucherName = voucherName;
  }

  public getVoucherName(): string {
    return this.voucherName;
  }

  public setEzetapMachineId(machineId: string): void {
    this.ezetapMachineId = machineId;
  }

  public getEzetapMachineId(): string {
    return this.ezetapMachineId;
  }

}
