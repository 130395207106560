/* eslint-disable  @typescript-eslint/no-explicit-any */
import { EventEmitter, Injectable, NgZone } from '@angular/core';
import { ApiService, ApplePayPayload, ApplePayPaymentPayload } from './api.service';
import { DataService } from './data.service';
import { DomainService } from './domain.service';
import { PaymentGateway } from './online-payment.service';

@Injectable({
  providedIn: 'root'
})
export class ApplePayService {

  public ApplePaySuccess = new EventEmitter<boolean>();

  private transactionId: string;
  private restaurantId: string;
  private defaultPG: string;

  constructor(
    private dataService: DataService,
    private apiService: ApiService,
    private ngZone: NgZone,
    private domainService: DomainService,
  ) {
    this.restaurantId = this.dataService.getRestaurant()?.id;
    this.setupPG();
  }

  public isApplePayAvailable(): boolean {
    try {
      return (window as any).ApplePaySession && (window as any).ApplePaySession.canMakePayments();
    } catch (error) {
      return false;
    }
  }

  public startApplePaySession(appleSession, orderIds: string[]) {
    this.handleApplePayEvents(appleSession, orderIds);
    appleSession.begin();
  }

  public getTransactionId(): string {
    return this.transactionId;
  }

  private handleApplePayEvents(appleSession, orderIds: string[]) {
    let paymentSuccess = false;

    appleSession.onvalidatemerchant = (event) => {
      this.validateApplePaySession(event.validationURL, (merchantSession) => {
        appleSession.completeMerchantValidation(merchantSession);
      });
    };

    appleSession.onpaymentauthorized = (event) => {
      this.performTransaction(event.payment, orderIds, (outcome) => {
        if (outcome === 'SUCCESS') {
          appleSession.completePayment((window as any).ApplePaySession.STATUS_SUCCESS);
          paymentSuccess = true;
        } else {
          appleSession.completePayment((window as any).ApplePaySession.STATUS_FAILURE);
        }

        // This is because we are triggering a navigation from within a global callback
        this.ngZone.run(() => {
          // Added 3 seconds wait until the apple pay sheet disappears
          setTimeout(() => {
            this.ApplePaySuccess.emit(paymentSuccess);
          }, 3000);
        });
      });
    };
  }

  private validateApplePaySession(appleUrl, callback) {
    const payload: ApplePayPayload = {
      restaurant_id: this.dataService.getRestaurant().id,
      applepay_url: appleUrl,
      subdomain: this.domainService.getSubdomain()
    };
    this.apiService.getApplePaySession(payload).subscribe(response => {
      callback(response);
    });
  }

  private performTransaction(details, orderIds: string[], callback) {
    const payload: ApplePayPaymentPayload = {
      applepay: true,
      subdomain: this.domainService.getSubdomain(),
      order_ids: orderIds,
      applepay_data: {token: JSON.stringify(details.token.paymentData)}
    };

    if (this.defaultPG === PaymentGateway.MAFPay) {
      this.apiService.submitApplePayMaf(payload).subscribe(res => {
        if (res.result === 'SUCCESS') {
          this.transactionId = res.order.id.substr(4);
        }
        callback(res.result);
      });
    } else if (this.defaultPG === PaymentGateway.Payfort) {
      const payfortPayload = {
        order_ids: orderIds,
        applepay_data: details
      };
      this.apiService.submitApplePayPayfort(payfortPayload, this.restaurantId).subscribe(res => {
        if (res.result === 'SUCCESS') {
          this.transactionId = res.order.id.substr(4);
        }
        callback(res.result);
      });
    } else if (this.defaultPG === PaymentGateway.TotalProcessing) {
      this.apiService.submitApplePayTP(payload).subscribe(res => {
        if (res.result === 'SUCCESS') {
          this.transactionId = res.order.id.substr(4);
        }
        callback(res.result);
      });
    } else {
      this.apiService.getNiSession(payload).subscribe(res => {
        if (res.result === 'SUCCESS') {
          this.transactionId = res.order.id.substr(4);
        }
        callback(res.result);
      });
    }
  }

  private setupPG(): void {
    if (!this.restaurantId) {
      return;
    }
    const methods = this.dataService.getPaymentMethods();
    const method = methods?.find(m => m.is_default_payment_gateway === true);
    if (!method) {
      return;
    } else {
      this.defaultPG = method.key;
    }
  }
}
