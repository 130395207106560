
<div class="charge-to-my-room-container">
  <form id="charge-to-my-room" [formGroup]="formGroup" (ngSubmit)="confirm()">
    <p class="title">{{ 'HOTEL_PREFERENCE.ROOM' | translate }}</p>

    <div class="row">
      <mat-form-field>
        <mat-label>{{ 'BILL.NAME' | translate }}</mat-label>
        <input matInput required formControlName="name">
      </mat-form-field>
    </div>

    <div class="row">
      <mat-form-field>
        <mat-label>{{ 'BILL.ROOM_NUMBER' | translate }}</mat-label>
        <input matInput required formControlName="roomNumber">
      </mat-form-field>
    </div>

    <div class="buttons">
      <button type="submit" class="confirm" mat-raised-button>{{ 'ACTIONS.SUBMIT' | translate }}</button>
    </div>
  </form>
</div>
