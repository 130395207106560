import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { debounceTime, Subscription } from 'rxjs';
import { Item, Table } from '../../services/api.service';
import { CartService } from '../../services/cart.service';
import { DataService } from '../../services/data.service';
import { DialogService } from '../../services/dialog.service';
import { SuperSectionService } from '../../services/super-section.service';
import { ThemeSourcePipe } from '../../shared/pipes/theme-source.pipe';
import { ActivableSection, DisplayableSection, MenuService } from '../menu.service';
import { Promotion } from '../promotions/promotions.component';

@Component({
  selector: 'app-super-sections-grid',
  templateUrl: './super-sections-grid.component.html',
  styleUrls: ['./super-sections-grid.component.scss']
})
export class SuperSectionsGridComponent implements OnInit, OnDestroy {
  @Input() menuOpened = true;

  public logoUrl: string;
  public logoStyle;
  public superSections: (DisplayableSection | undefined)[];
  public currentTable: Table;
  public searchItemsSection: ActivableSection;
  public searchSections: DisplayableSection[];
  public isSearchActivated = false;
  public totalPrice = 0;
  public quantity = 0;
  public promotions: Promotion[];

  private superSectionSubscription = Subscription.EMPTY;
  private logoSubscription = Subscription.EMPTY;
  private tableSubscription = Subscription.EMPTY;
  private menuSearchSubscription = Subscription.EMPTY;
  private cartSubscription = Subscription.EMPTY;

  constructor(
    private dataService: DataService,
    public menuService: MenuService,
    private router: Router,
    private route: ActivatedRoute,
    private superSectionService: SuperSectionService,
    private cartService: CartService,
    private dialogService: DialogService,
    private translateService: TranslateService,
    private themeSourcePipe: ThemeSourcePipe
  ) {}

  public ngOnInit(): void {
    this.currentTable = this.dataService.getTable();
    this.checkLogo();

    if (!this.superSections) {
      this.menuService.displayableSuperSections$
        .subscribe(sections => {
          this.superSections = sections;
        });
    }

    this.tableSubscription = this.dataService
      .tableEmitter
      .subscribe(table => {
      this.currentTable = table;
    });

    this.superSectionSubscription = this.superSectionService
        .menuEmitter
        .subscribe(res => {
          this.menuOpened = res;
    });
    this.getTotalPrice();
    this.cartSubscription = this.cartService.cartUpdated.subscribe(() => this.getTotalPrice());
    this.promotions = this.dataService.getPromotions();
  }

  public ngOnDestroy(): void {
    this.superSectionSubscription.unsubscribe();
    this.logoSubscription.unsubscribe();
    this.tableSubscription.unsubscribe();
    this.cartSubscription.unsubscribe();
  }

  public select(section?: string): void {
    if (section) {
      this.router.navigate(['/menu', encodeURIComponent(section)]);
    } else if (!section && !this.route.snapshot.params['section']) {
      this.menuService.setCurrentSectionByName();
    }
    if (this.isSearchActivated) {
      this.resetSearch();
    }
    this.menuOpened = false;
  }

  public openCart(): void {
    this.dialogService.openCart();
  }

  public initSearch(): void {
    this.menuSearchSubscription =
      this.menuService.gridSearch.controls.search.valueChanges
        .pipe(debounceTime(300))
        .subscribe((v) => {
          if (v && v.length > 0) {
            this.isSearchActivated = true;
            const items = this.menuService.getSearchItems(v.trim());
            this.searchSections = this.menuService.getSearchSections(v.trim());
            this.setSearchItemsSection(items);
          } else {
            this.isSearchActivated = false;
          }
        });
  }

  public getSearchPlaceholder(): string {
    const copy = this.dataService.getIsParent() ? 'ACTIONS.SEARCH_PLACEHOLDER' : 'MENU.SEARCH';
    return this.translateService.instant(copy);
  }

  public getSearchResultsCopy(): string {
    const copy = this.dataService.getIsParent() ? 'MENU.BRANDS' : 'MENU.SECTIONS';
    return this.translateService.instant(copy);
  }

  private setSearchItemsSection(items: Item[]): void {
    const searchSection: ActivableSection = Object.assign({});
    searchSection.name = '';
    searchSection.description = '';
    searchSection.items = items ? items : [];
    searchSection.sub_sections = [];
    this.searchItemsSection = searchSection;
  }

  // Since this component never gets destroyed (as long as the app is open), we are cleaning the search subs manually
  private resetSearch(): void {
    this.isSearchActivated = false;
    this.searchSections = [];
    this.searchItemsSection.items = [];
    this.menuService.gridSearch.reset();
    this.menuSearchSubscription.unsubscribe();
  }

  private getTotalPrice(): void {
    const totalQuantity = this.cartService.getCart().cartLines.reduce((accumulator, cartLine) => accumulator + cartLine.quantity, 0);

    const lines = this.cartService.getCart().cartLines.map(cartLine => {
      const priceModifiers: number = cartLine.modifiers.reduce((acc, modifier) => acc + modifier.price, 0);

      return {
        id: cartLine.id,
        itemName: cartLine.item.name,
        quantity: cartLine.quantity,
        modifiers: cartLine.modifiers,
        total: (cartLine.item.price + priceModifiers) * cartLine.quantity
      };
    });

    this.quantity = totalQuantity;

    this.totalPrice = lines.reduce((accumulator, cartLine) => accumulator + cartLine.total, 0);
  }


  // This method checks if we have a logo in the assets repo or in the cms
  private checkLogo(): void {
    const logoUrl = this.themeSourcePipe.transform('grid_logo.png');

    const img = new Image();
    img.src = logoUrl;

    img.onload = () => {
      this.logoUrl = logoUrl;
      this.logoStyle = {
        background: `url('${this.logoUrl}') center no-repeat`,
        'background-size': 'contain',
        height: '100%'
       };
    };

    img.onerror = () => {
      this.logoSubscription = this.dataService.logoUrl$.subscribe(url => {
        this.logoUrl = url || '';
        if (this.logoUrl) {
          this.logoStyle = {
            background: `url('${this.logoUrl}') center no-repeat`,
            'background-size': 'contain',
            height: '100%'
          };
        }
      });
    };
  }
}
