<div id="waiter">
  <div class="special-notes">
    <div class="options">
      <mat-form-field class="select-form" *ngIf="options?.length > 0">
        <mat-label>{{ 'WAITER.HELP' | translate }}</mat-label>
        <mat-select [(value)]="selectedOption">
          <mat-option *ngFor="let option of options" [value]="option.id">{{option.name}}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div class="textarea-container">
      <p *ngIf="loaded && !options" class="title">{{ 'WAITER.HELP' | translate }}</p>
      <textarea [placeholder]="'WAITER.ADDITIONAL_COMMENTS' | translate" [(ngModel)]="comment"></textarea>
    </div>
  </div>

  <div class="buttons">
    <button class="confirm" mat-raised-button (click)="confirm(true)">{{ 'ACTIONS.SUBMIT' | translate }}</button>
    <button mat-raised-button (click)="confirm(false)">{{ 'ACTIONS.CANCEL' | translate }}</button>
  </div>
</div>
