import { Component, Inject } from '@angular/core';
import { MAT_BOTTOM_SHEET_DATA, MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { PaymentMethod } from '../../services/api.service';
import { ApplePayService } from '../../services/apple-pay.service';

@Component({
  selector: 'app-payment-method',
  templateUrl: './payment-method.component.html',
  styleUrls: ['./payment-method.component.scss']
})
export class PaymentMethodComponent {

  public paymentType;
  public paymentMethods: PaymentMethod[];

  constructor(
// eslint-disable-next-line  @typescript-eslint/no-explicit-any
    @Inject(MAT_BOTTOM_SHEET_DATA) public data: any,
    private ref: MatBottomSheetRef<PaymentMethodComponent>,
    private applePayService: ApplePayService,
  ) {
    this.paymentType = this.data.paymentType;
    this.paymentMethods = data.paymentMethods;
    if (!this.applePayService.isApplePayAvailable()) {
      this.paymentMethods = this.paymentMethods.filter((type) => type.key !== 'applepay');
    }
  }

  public done(): void {
    this.ref.dismiss({
      paymentType: this.paymentType
    });
  }
}
