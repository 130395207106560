import { Component, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-font-open',
  templateUrl: './open.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./open.component.scss']
})
export class FontOpenComponent {

  constructor() {
    document.body.style.setProperty('font-family', 'Open Sans, sans-serif');
  }
}
