import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

/**
 * This service will return you to the "please rescan" page after 10 minutes
 */
@Injectable({
  providedIn: 'root'
})
export class RescanService {
  private readonly intervalInMs: number = 60000; // 1 minute
  private readonly targetTimeInMs: number = 600000; // 10 minutes
  private currentTimeInMs: number;
  private intervalId: number;

  constructor(
    private dialog: MatDialog,
  ) {}

  public startTimer(): void {
    this.currentTimeInMs = 0;
    this.intervalId = window.setInterval(() => {
      this.check();
    }, this.intervalInMs);
  }

  public async openRescan() {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const { QRScanComponent } = await import(
      /* webpackPrefetch: true */
      '../qrscan/qrscan.component'
    );
    this.dialog.open(QRScanComponent, {
      disableClose: true,
      width: '100%',
      height: '100%',
      maxWidth: '100vw',
      maxHeight: '100vh',
    });
  }

  private check(): void {
    const interval = this.targetTimeInMs - this.currentTimeInMs;
    if (interval <= 0) {
      window.clearInterval(this.intervalId);
      this.openRescan();
      return;
    }

    this.currentTimeInMs += this.intervalInMs;
  }
}
