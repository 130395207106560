<div *ngIf="hasWallpaper" class="wallpaper" [ngStyle]="wallpaperStyle"></div>
<app-banner *ngIf="getBanner()" [action]="currentRestaurant?.banner_action" [banner]="getBanner()" [logo]="currentRestaurant?.logo_url ? currentRestaurant?.logo_url : ''"></app-banner>
<div [ngClass]="hasWallpaper ? 'background-wallpaper' : 'background-no-wallpaper'">
  <div class="fixed-top">
    <div class="top-bar">
      <h2><span class="section-name" *ngIf="currentSectionName">{{currentSectionName}}</span></h2>
      <img class="search-icon" [src]="'search.svg' | themeSource" alt="search" (click)="toggleSearch()" />
      <img class="filters-icon" *ngIf="hasFilters" (click)="openFilters()" [src]="(!filtersActive ? 'filters.svg': 'active-filters.svg') | themeSource" alt="filters" />
      <img class="settings-icon" *ngIf="hasMultipleCurrencies || hasMultipleLanguages" (click)="openLanguages()" [src]="(hasMultipleCurrencies ? 'language-currency.svg' : 'language.svg') | themeSource" alt="filters" />
    </div>
    <div [ngClass]="searchOpened ? 'open' : 'close'"  class="search" [formGroup]="menuService.searchForm">
      <input id="search-input" matInput formControlName="search">
      <mat-icon *ngIf="searchOpened" (click)="closeSearch()">close</mat-icon>
    </div>
  </div>
  <app-super-section></app-super-section>
  <p class="menu-description" *ngIf="description">{{description}}</p>
</div>
<app-bottom-bar *ngIf="currentRestaurant" [venue]="currentRestaurant"></app-bottom-bar>
<app-super-sections-container></app-super-sections-container>
