/* eslint-disable  @typescript-eslint/no-explicit-any */
import { Injectable, EventEmitter } from '@angular/core';
import { throwError, Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { ApiService, MastercardPayload, MastercardResponse } from './api.service';
import { environment } from '../../environments/environment';

export enum PaymentState {
  Cancelled = 'cancelled',
  Error = 'error',
  Complete = 'complete'
}

@Injectable({
  providedIn: 'root'
})
export class MasterCardService {

  public paymentState = new EventEmitter<PaymentState>();
  private loaded: boolean;

  constructor(
    private apiService: ApiService,
  ) {
    this.loaded = false;
  }

  public setupScript(method: string): Promise<boolean> {
    let src: string;
    if (method === 'network-intl') {
      src = environment.urls.ni;
    } else {
      src = environment.urls[method];
    }
    // eslint-disable-next-line  @typescript-eslint/no-unused-vars
    return new Promise((resolve, reject) => {
      if (!this.loaded) {
        const checkoutScript = document.createElement('script');
        checkoutScript.src = src;
        checkoutScript.type = 'text/javascript';

        (window as any).cancelCallback = () => {
          this.loaded = false;
          this.paymentState.emit(PaymentState.Cancelled);
        };
        // eslint-disable-next-line  @typescript-eslint/no-unused-vars
        (window as any).errorCallback = (error) => {
          this.loaded = false;
          this.paymentState.emit(PaymentState.Error);
        };

        checkoutScript.setAttribute('data-cancel', 'cancelCallback');
        checkoutScript.setAttribute('data-error', 'errorCallback');

        checkoutScript.onload = () => {
          this.loaded = true;
          resolve(true);
        };
        // eslint-disable-next-line  @typescript-eslint/no-unused-vars
        checkoutScript.onerror = (error: any) => resolve(false);
        document.body.appendChild(checkoutScript);
      } else {
        resolve(true);
      }
    });
  }

  public getSession(niPayload: MastercardPayload): Observable<MastercardResponse> {
    return this.apiService
      .getNiSession(niPayload)
      .pipe(
        map((response: MastercardResponse) => response), catchError(error => throwError(error))
      );
  }

  public configureSession(session: MastercardResponse): void {
      const config: any = {
        session: {
          id: session.session.id
        }
      };
      (window as any).Checkout.configure(config);
  }

  public showLightBox(): void {
    (window as any).Checkout.showPaymentPage();
  }
}
