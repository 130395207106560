<div class="filters" [ngClass]="{'opened': menuOpened}">
  <div class="filter-title">
    <span *ngIf="currentRestaurant?.type !== venueTypes.ReadOnly">{{ currentTable?.table_type }} {{ currentTable?.name }}</span>
    <span *ngIf="currentRestaurant?.type === venueTypes.ReadOnly">{{ currentTable?.name }}</span>
    <button (click)="select()">
      <img [src]="'close.svg' | themeSource" alt="close"/>
    </button>
  </div>
  <ul *ngIf="superSections">
    <ng-container *ngFor="let section of superSections">
      <li (click)="select(section)">{{ section }}</li>
    </ng-container>
  </ul>
  <div class="ss-list-image-container">
    <img [src]="'opaala-super-sections.svg' | themeSource" alt="Opaala">
  </div>
</div>

