<div (click)="hideSplash()" class="splash-screen" *ngIf="!isLoaded || isMenuClosed">
  <img *ngIf="!isMenuClosed" [ngClass]="{loaded: imageSrc && imagesLoadedCount >= 2}" class="splash-screen-icon" [defaultImage]="'opaala-splash.svg' | themeSource" [lazyLoad]="imageSrc ? imageSrc : ('opaala-splash.svg' | themeSource)" (load)="markLoaded()">
  <img *ngIf="isMenuClosed && imageSrc" class="splash-screen-icon loaded" [src]="imageSrc" (load)="markLoaded()">

  <div class="menu-closed-message" *ngIf="isMenuClosed && !imageSrc">
    <p *ngFor="let lang of languages">
      {{menuClosedMessage[lang] || ''}}
    </p>
  </div>
  
  <button *ngIf="!isMenuClosed && imageSrc && imagesLoadedCount >= 2" mat-raised-button>
    <span>
      {{ 'MENU.MENU' | translate }}
    </span>
  </button>
</div>