import { Component, HostListener, Inject, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { ApiService, GetOrder, MafPayPaymentPayload, OrderStatus } from '../services/api.service';
import { CartService } from '../services/cart.service';
import { DialogService } from '../services/dialog.service';
import { MafPayDetails, OnlinePaymentService } from '../services/online-payment.service';
import { OrderService } from '../services/order.service';

@Component({
  selector: 'app-mafpay',
  templateUrl: './mafpay.component.html',
  styleUrls: ['./mafpay.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class MafpayComponent implements OnInit, OnDestroy {

  public hasVoucher = false;
  public threedsauthid: string;
  public isLoading = false;

  private order: GetOrder;
  private paymentSubmittedSub = Subscription.EMPTY;
  private feedbackSub = Subscription.EMPTY;

  constructor(
    public dialogRef: MatDialogRef<MafpayComponent>,
    @Inject(MAT_DIALOG_DATA)  public data: MafPayDetails,
    private onlinePaymentService: OnlinePaymentService,
    private apiService: ApiService,
    private orderService: OrderService,
    private cartService: CartService,
    private dialogService: DialogService
  ) {}

  @HostListener('tokenizationComplete', ['$event'])
  public getTokenHandler(event: CustomEvent) {
    const payload: MafPayPaymentPayload = {
      card_token: event.detail.cardToken,
      transaction_id: event.detail.extraData1
    };
    this.paymentSubmittedSub = this.apiService.submitMafPayPayment(payload).subscribe(res => {
      this.threedsauthid = res.three_ds_auth_id;
    // eslint-disable-next-line  @typescript-eslint/no-unused-vars
    },(error) => {
      this.onlinePaymentService.paymentCancelled.emit(true);
      this.dialogRef.close();
      this.dialogService.openPaymentFailed();
    });
  }

  @HostListener('processComplete', ['$event'])
  public paymentCompleteHandler(event: CustomEvent) {
    // eslint-disable-next-line  @typescript-eslint/no-unused-vars
    this.feedbackSub = this.apiService.submitMafFeedback(event.detail).subscribe(res => {
      this.onlinePaymentService.paymentSheetClosed.emit(true);
      if (event.detail.responseMessage === 'Success') {
        this.order.status = OrderStatus.Paid;
        this.orderService.setLastOrder(this.order);
        this.cartService.clearCart();
        this.dialogService.closeDialogs();
      } else {
        this.onlinePaymentService.paymentCancelled.emit(true);
        this.dialogRef.close();
        this.dialogService.openPaymentFailed();
      }
    });
  }

  public ngOnInit(): void {
    this.order = this.data.order;
    if (this.data.discount_amount > 0 ) {
      this.hasVoucher = true;
    }
  }

  public ngOnDestroy(): void {
    this.paymentSubmittedSub.unsubscribe();
    this.feedbackSub.unsubscribe();
  }

  public submitPayment(): void {
    this.isLoading = true;
  }

  public close(): void {
    this.dialogRef.close();
    this.onlinePaymentService.paymentCancelled.emit(true);
  }

}
