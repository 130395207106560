import { NgModule, ErrorHandler, Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import * as Sentry from '@sentry/browser';

if (isSentryAvailable()) {
  const environmentName: string = environment.production ? 'live' : 'staging';

  Sentry.init({
    dsn: environment.tpl.sentry,
    environment: environmentName
  });
}

@Injectable()
export class SentryErrorHandler implements ErrorHandler {
  handleError(error) {
    const chunkFailedMessage = /Loading chunk [\d]+ failed/;
    if (chunkFailedMessage.test(error.message)) {
      window.location.reload();
    }

    Sentry.captureException(error.originalError || error);
    throw error;
  }
}

@NgModule({
  providers: [{ provide: ErrorHandler, useClass: SentryErrorHandler }]
})
export class SentryModule {

}

function isSentryAvailable(): boolean {
  return !! environment.tpl.sentry;
}
