import { Injectable } from '@angular/core';
import { Item, Menu, Schedule, Section } from './api.service';
import { DateTime } from 'luxon';
import { ReplaySubject } from 'rxjs';
import { ActivableSection, MenuService } from '../menu/menu.service';
import { SplashScreenService } from './splash-screen.service';

export interface SingleSchedule {
  schedule: Schedule;
  item?: Item;
  section?: Section;
  isActive: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class ScheduleService {

  public schedule$: ReplaySubject<SingleSchedule> = new ReplaySubject(1);

  private menu: Menu;
  private sections: ActivableSection[]; // A flat list of all sections.
  private superSections: ActivableSection[];

  private readonly intervalInMs: number = 60000;

  private checkSectionInProgress = false;

  constructor(
    private menuService: MenuService,
    private splashScreenService: SplashScreenService
  ) { }

  public registerSchedules(menu: Menu): void {
    this.menu = menu;

    // Get a list of Activable Super Sections
    this.superSections = this.menu.sections.map((section: Section) => this.makeActivableSection(section));

    const ids: string[] = [];
    this.sections = [];

    // Get a flat list of items without any duplicates
    this.superSections.forEach((section: ActivableSection) => {
      const alreadyExists: boolean = ids.indexOf(section.id) !== -1;
      if (!alreadyExists) {
        ids.push(section.id);
        this.sections.push(section);
      }
      section.sub_sections.forEach((subSection: ActivableSection) => {
        const alreadySubExists: boolean = ids.indexOf(subSection.id) !== -1;
        if (! alreadySubExists) {
          ids.push(subSection.id);
          this.sections.push(subSection);
        }
      });
    });
    this.splashScreenService.hideSplash();

    // TODO: this is not great as well
    // It was originally in the constructor, but moved here because
    // in SSR mode, it was crashing with an error.
    this.check();
    window.setInterval(() => {
      this.check();
    }, this.intervalInMs);
  }

  public checkScheduleFromSections(): void {
    if (this.checkSectionInProgress) {
      return;
    }

    this.checkSectionInProgress = true;

    this.sections.forEach((section: ActivableSection) => {
      // check if we have an active schedule
      const activeSchedule: Schedule | undefined = section.schedules.find((schedule: Schedule) => this.isActive(schedule));

      // TODO: could use a refacto
      if (activeSchedule) {
        const single: SingleSchedule = {
          schedule: activeSchedule,
          section,
          isActive: true
        };
        this.updateSection(single);
      } else if (section.schedules.length > 0) {
        const single: SingleSchedule = {
          schedule: {} as Schedule,
          section,
          isActive: false
        };
        this.updateSection(single);
      }
    });

    // Filter out non-active sections
    const activeSections = this.sections.filter((section: ActivableSection) => section.isActive);


    // Retrieve the updated list of super-sections (which includes updated subsections as well)
    //  and emit it to the menu service.
    const updatedSections: ActivableSection[] = activeSections
      .filter(o => this.superSections
        .some((section) => o.id === section.id) // TODO: .each(section.isActive)
      );

    this.menu.sections = updatedSections;
    this.menuService.setMenu(this.menu);
    this.checkSectionInProgress = false;
  }

  public checkScheduleFromItems(): void {
    const ids: string[] = [];
    const items: Item[] = [];

    // Get a flat list of items without any duplicates
    this.sections.forEach((section: Section) => {
      section.items.forEach((item: Item) => {
        const alreadyExists: boolean = ids.indexOf(item.id) !== -1;
        if (! alreadyExists) {
          ids.push(item.id);
          items.push(item);
        }
      });
    });

    items.forEach((item: Item) => {
      const activeSchedule: Schedule | undefined = item.schedules.find((schedule: Schedule) => this.isActive(schedule));

      // TODO: could use a refacto
      if (activeSchedule) {
        const single: SingleSchedule = {
          schedule: activeSchedule,
          item,
          isActive: true
        };
        this.schedule$.next(single);
      } else if (item.schedules.length > 0) {
        const single: SingleSchedule = {
          schedule: {} as Schedule,
          item,
          isActive: false
        };
        this.schedule$.next(single);
      }
    });
  }

  private makeActivableSection(section: Section): ActivableSection {
    return {
      name: section.name,
      id: section.id,
      description: section.description,
      items: section.items,
      schedules: section.schedules,
      sub_sections: section.sub_sections.length === 0 ?
        [] : section.sub_sections.map(this.makeActivableSection),
      isActive: true,
      banner_url: section.banner_url,
      logo_url: section.logo_url
    };
  }

  private updateSection(schedule): void {
    if (schedule.section === undefined) {
      return;
    }

    const index: number = this.sections
      .findIndex((section) => section.id === schedule.section.id);

    if (index > -1) {
      this.sections[index].isActive = schedule.isActive;
    }
  }

  private isTimeBetween(schedule: Schedule): boolean {
    const startTime: string = schedule.start_time;
    const endTime: string = schedule.end_time;

    const currentDate = DateTime.fromJSDate(new Date(), { zone: schedule.timezone });

    const startDate = currentDate.set({
      hour: parseInt(startTime.split(':')[0], 10),
      minute: parseInt(startTime.split(':')[1], 10),
      second: 0
    });

    const endDate = currentDate.set({
      hour: parseInt(endTime.split(':')[0], 10),
      minute: parseInt(endTime.split(':')[1], 10),
      second: 0
    });

    const valid: boolean = startDate < currentDate && endDate > currentDate;
    return valid;
  }

  /**
   * Compare today's week day against the one in the schedule.
   * Returns true if it is the same, false otherwise.
   * Note: schedule.week_day uses ISO week days (Monday = 1 and Sunday = 7)
   * Note: Javascript getDay() is different (Sunday = 0 and Saturday = 6)
   */
  private isToday(schedule: Schedule): boolean {
    const weekDay: number = new Date().getDay();
    const today: number = weekDay === 0 ? 7 : weekDay;
    const day: number = schedule.week_day;
    return today === day;
  }

  private isActive(schedule: Schedule): boolean {
      return this.isToday(schedule) &&
        this.isTimeBetween(schedule);
  }

  private check(): void {
    this.checkScheduleFromSections();
    this.checkScheduleFromItems();
  }

}
