<div class="card" [ngClass]="{'with-image': item.image_url, 'unavailable': unavailable}">
  <div class="image-holder" *ngIf="item.image_url" (click)="increase()">
    <img [defaultImage]="'/assets/images/item-placeholder.png'" [lazyLoad]="item.image_url" />
  </div>
  <div class="item" [ngClass]="{'float-right': item.image_url}">
    <div class="left" (click)="increase()">
      <div class="top">
        <p class="name">{{ item.name }}</p>
      </div>
      <div class="description">
        {{item.description}}
      </div>
      <div class="price-calories">
        <span *ngIf="item.price !== 0" class="price">{{ item.price | currencySpace }}</span>
        <span class="calories">
          <span *ngIf="item.calories"> (</span>
          <span translate [translateParams]="{'calories': item.calories}" *ngIf="item.calories">COMMON.CALORIES</span>
          <span *ngIf="item.calories">)</span>
        </span>
      </div>
    </div>
    <div class="right" *ngIf="!readonly" [ngClass]="quantity > 0 && '!readonly' ">
      <span class="symbol" (click)="increase()">+</span>
      <span class="quantity" *ngIf="quantity > 0">{{ quantity }}</span>
      <span *ngIf="quantity > 0" class="symbol" (click)="decrease()">-</span>
    </div>
  </div>
</div>

