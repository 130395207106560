<div class="language-start-container ">
  <div *ngIf="venueName" class="title">
    <span>{{ venueName }}</span>
  </div>

  <div class="buttons">
    <button *ngFor="let lang of languages" class="language-button" mat-raised-button (click)="selectLanguage(lang.key)">
      <span>
        {{ lang.label | translate }}
      </span>
    </button>
  </div>

  <div class="image-container">
    <img [src]="'opaala-splash.svg' | themeSource" alt="Opaala">
  </div>
</div>


