<div class="dialog-content">
  <p class="message">
    {{ 'ORDER.REJECTED' | translate }}
  </p>

  <div class="items-list" *ngIf="items.length > 0">
    <p *ngFor="let item of items">
      *{{item.name}}
    </p>
  </div>

  <div class="items-list" *ngIf="modifiers.length > 0">
    <p *ngFor="let modifier of modifiers">
      *{{modifier.name}}
    </p>
  </div>

  <div class="button-container">
    <button (click)="close()" class="confirm" mat-raised-button>{{ 'CART.GOT_IT' | translate }}</button>
  </div>
</div>

