import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { SplashScreenService } from '../services/splash-screen.service';

@Component({
  selector: 'app-splash-screen',
  templateUrl: './splash-screen.component.html',
  styleUrls: ['./splash-screen.component.scss'],
})
export class SplashScreenComponent implements OnInit, OnDestroy {
  public imageSrc: string;
  public isLoaded = false;
  public imagesLoadedCount = 0;
  public isMenuClosed = false;
  public languages: string[];

  private splashSub = Subscription.EMPTY;
  private disableSub = Subscription.EMPTY;

  public menuClosedMessage = {
    en: 'Our menu is currently closed for ordering. We apologize for the inconvenience.',
    ar: 'قائمة الطعام مغلقة حالياً للطلب. نعتذر عن الازعاج',
    ru: 'Наше меню в настоящее время закрыто для заказов. Приносим извинения за неудобства'
  }

  constructor(
    private splashScreenService: SplashScreenService,
  ) { }

  public ngOnInit(): void {
    this.splashSub = this.splashScreenService.splashEmitter
      .subscribe((url) => {
        this.isLoaded = false;
        if (url.hide) {
          this.isMenuClosed = true;
          this.imageSrc = url.image_url;
          this.languages = url.languages || ['en', 'ar', 'ru'];
        } else {
          this.showSplash(url.image_url);
        }
      });

    this.disableSub = this.splashScreenService.disabledEmitter
      .subscribe( () => {
        this.isLoaded = true;
      });
  }

  public ngOnDestroy(): void {
    this.splashSub.unsubscribe();
    this.disableSub.unsubscribe();
  }

  public hideSplash(): void {
    if (!this.imageSrc || this.isMenuClosed) {
      return;
    }
    this.isLoaded = true;
  }

  /* This method is to keep track of the images loaded and increment the counter on each image lazy load.
     First image loaded is the bouncing 'powered by opaala' and the second is the splash screen.
     So when the splash screen is lazy loaded, the counter would be 2.
  */
  public markLoaded(): void {
    if (this.imageSrc) {
      this.imagesLoadedCount++; // first image loaded is bouncing 'powered by opaala' and the second is the splash screen.
    }
  }

  private showSplash(url: string) {
    if (url) {
      this.imageSrc = url;
    } else {
      this.splashScreenService.loadedEmitter
        .subscribe(() => {
          this.isLoaded = true;
      });
    }
    // hide it after 10 seconds if the user never clicked on the screen
    setTimeout(
      () => {
        this.isLoaded = true;
      },
      10000
    );
  }
}
