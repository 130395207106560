import { Component, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-font-nunito',
  templateUrl: './nunito.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./nunito.component.scss']
})
export class FontNunitoComponent {

  constructor() {
    document.body.style.setProperty('font-family', 'Nunito, sans-serif');
  }
}
