<div class="modifier-content">
  <div class="item-title">
    <h2>{{ itemName }}</h2>
    <span class="link" (click)="confirm(false)">
      <img [src]="'close-black.svg' | themeSource">
    </span>
  </div>
  <div class="content-wrapper">
    <div class="modifier-image-holder" *ngIf="data.item.image_url" (click)="fullScreenImg = true">
      <img [defaultImage]="'/assets/images/item-placeholder.png'" [lazyLoad]="data.item.image_url" />
    </div>
    <p class="modifier-description">{{description}}</p>
    <div class="mod-content" *ngFor="let modifier_group of data.item.modifier_groups">
      <div class="title-container">
        <span class="modifier-title">{{ modifier_group.name }}</span>
        <span *ngIf="!modifier_group.is_valid && submitted" class="required-label">{{ 'COMMON.REQUIRED' | translate}}!</span>
      </div>
      <!-- TODO: Handle the fact that you may NOT select anything -->
      <div class="modifiers" *ngIf="modifier_group.type === 'single'">
        <mat-radio-group *ngIf="modifier_group.display_type === 'default'" [(ngModel)]="models[modifier_group.id]">
          <mat-radio-button *ngFor="let modifier of modifier_group.modifiers" value="{{modifier.id}}"
            (click)="deselect(modifier_group.id, modifier.id)">
            <span class="mod-name">{{ modifier.name }}</span>
            <span class="mod-price" *ngIf="modifier.price">+ {{ modifier.price | currencySpace }}</span>
            <ng-container *ngIf="modifier.calories">
               (<span translate [translateParams]="{'calories': modifier.calories}">COMMON.CALORIES</span>)
            </ng-container>
          </mat-radio-button>
        </mat-radio-group>

        <mat-select *ngIf="modifier_group.display_type === 'dropdown'" class="bordered" [(ngModel)]="models[modifier_group.id]" (valueChange)="onChange()">
          <mat-option *ngFor="let modifier of modifier_group.modifiers" [value]="modifier.id">
            <span class="mod-name">{{ modifier.name }}</span>
            <span *ngIf="modifier.price">+ {{ modifier.price | currencySpace }}</span>
            <ng-container *ngIf="modifier.calories">
              (<span translate [translateParams]="{'calories': modifier.calories}">COMMON.CALORIES</span>)
            </ng-container>
          </mat-option>
        </mat-select>
      </div>

      <!-- TODO: First value selected by default -->
      <div class="modifiers" *ngIf="modifier_group.type === 'single-compulsory'">
        <mat-radio-group *ngIf="modifier_group.display_type === 'default'" (change)="onChange(modifier_group)" [(ngModel)]="models[modifier_group.id]">
          <mat-radio-button *ngFor="let modifier of modifier_group.modifiers" value="{{modifier.id}}">
            <span class="mod-name">{{ modifier.name }}</span>
            <span class="mod-price" *ngIf="modifier.price">+ {{ modifier.price | currencySpace }}</span>
            <ng-container *ngIf="modifier.calories">
               (<span translate [translateParams]="{'calories': modifier.calories}">COMMON.CALORIES</span>)
            </ng-container>
          </mat-radio-button>
        </mat-radio-group>

        <mat-select *ngIf="modifier_group.display_type === 'dropdown'" class="bordered" [(ngModel)]="models[modifier_group.id]" (valueChange)="onChange()">
          <mat-option *ngFor="let modifier of modifier_group.modifiers" [value]="modifier.id">
            <span class="mod-name">{{ modifier.name }}</span>
            <span *ngIf="modifier.price">+ {{ modifier.price | currencySpace }}</span>
            <ng-container *ngIf="modifier.calories">
              (<span translate [translateParams]="{'calories': modifier.calories}">COMMON.CALORIES</span>)
            </ng-container>
          </mat-option>
        </mat-select>
      </div>

      <!-- TODO: 0 to all -->
      <div class="modifiers" *ngIf="modifier_group.type === 'multiple' && !modifier_group.type_max">
        <mat-checkbox *ngFor="let modifier of modifier_group.modifiers" (change)="onChange()"
          [checked]="models[modifier_group.id][modifier.id]" [(ngModel)]="models[modifier_group.id][modifier.id]">
          <span class="mod-name">{{ modifier.name }}</span>
          <span class="mod-price" *ngIf="modifier.price">+ {{ modifier.price | currencySpace }}</span>
          <ng-container *ngIf="modifier.calories">
             (<span translate [translateParams]="{'calories': modifier.calories}">COMMON.CALORIES</span>)
          </ng-container>
        </mat-checkbox>
      </div>

      <div class="modifiers" *ngIf="modifier_group.type === 'multiple' && modifier_group.type_max">
        <div *ngFor="let modifier of modifier_group.modifiers" class="multiple-quantity">
          <div class="modifier-name">
            <span class="mod-name">{{ modifier.name }}</span>
            <span class="mod-price" *ngIf="modifier.price">+ {{ modifier.price | currencySpace }}</span>
            <ng-container *ngIf="modifier.calories">
               (<span translate [translateParams]="{'calories': modifier.calories}">COMMON.CALORIES</span>)
            </ng-container>
          </div>
          <div class="quantity">
            <span class="symbol" (click)="decrementModifier(modifier_group, modifier.id)" *ngIf="models[modifier_group.id][modifier.id] > 0">-</span>
            <span class="number" *ngIf="models[modifier_group.id][modifier.id] > 0">{{ models[modifier_group.id][modifier.id] }}</span>
            <span class="symbol" (click)="incrementModifier(modifier_group, modifier.id)">+</span>
          </div>
        </div>
      </div>

      <!-- TODO: min X max Y -->
      <div class="modifiers" *ngIf="modifier_group.type === 'min-max'">
        <p translate [translateParams]="{'min': modifier_group.type_min, 'max': modifier_group.type_max}">COMMON.CHOOSE_MODIFIERS</p>
        <mat-checkbox *ngFor="let modifier of modifier_group.modifiers" (change)="check(modifier_group, modifier.id)"
          [checked]="models[modifier_group.id][modifier.id]" [(ngModel)]="models[modifier_group.id][modifier.id]">
          <span class="mod-name">{{ modifier.name }}</span>
          <span class="mod-price" *ngIf="modifier.price">+ {{ modifier.price | currencySpace }}</span>
          <ng-container *ngIf="modifier.calories">
             (<span translate [translateParams]="{'calories': modifier.calories}">COMMON.CALORIES</span>)
          </ng-container>
        </mat-checkbox>
      </div>
    </div>
  </div>
</div>

<div class="modifiers-buttons" *ngIf="!readOnly">
  <div class="quantity-picker">
    <span class="symbol" (click)="updateQuantity(-1)">-</span>
    <span class="number">{{ quantity }}</span>
    <span class="symbol" (click)="updateQuantity(1)">+</span>
  </div>
  <button mat-raised-button class="confirm" (click)="confirm(true)">
    {{ 'ACTIONS.ADD_CART' | translate}}  <span class="modifiers-total-price">{{ total || 0 | currencySpace }}</span>
  </button>
</div>

<div *ngIf="fullScreenImg" class="full-screen-image-container" (click)="fullScreenImg = false">
  <img [src]="data.item.image_url" (click)="$event.stopPropagation()">
</div>
