<app-top-bar title="CART.TITLE" enableClose="false" isDialog="true" (closed)="close()"></app-top-bar>
<div class="cart-modal">
  <mat-progress-bar mode="indeterminate" color="accent" *ngIf="isLoading"></mat-progress-bar>

  <div class="empty-cart" *ngIf="!lines || lines.length === 0">
    {{ 'CART.EMPTY' | translate }}
  </div>

  <div class="cart-wrapper" *ngIf="lines && lines.length > 0">
    <div class="clear-cart" (click)="clearCart()">
      <div class="text">
        <span class="order">{{ 'CART.CLEAR' | translate }}</span>
      </div>
    </div>
    <div class="cart-items-container">
      <div class="cart-item" *ngFor="let line of lines">
        <div class="left">
          <img class="remove" [src]="'delete.svg' | themeSource" (click)="removeLine(line)">
          <div class="label">
            <p translate [translateParams]="{name: line.itemName}">CART.ITEM_LINE</p>
            <small *ngFor="let modifier of line.modifiers" class="middle text">
              * <span *ngIf="modifier.quantity > 1"> x{{ modifier.quantity }} </span> {{ modifier.name }}
              <span *ngIf="modifier.price > 0">
                (+ {{ modifier.price | currencySpace }})
              </span>
            </small>
            <small>
              <p class="special-instructions" (click)="addItemComment(line)">{{ line.comment || 'CART.ITEM_COMMENT' | translate}}</p>
            </small>
          </div>
          <div class="quantity-price">
            <div class="quantity">
              <span class="symbol" (click)="decrease(line)">-</span>
              <span class="number">{{line.quantity}}</span>
              <span class="symbol" (click)="increase(line)">+</span>
            </div>
            <span class="price">
              {{ line.total | currencySpace }}
            </span>
          </div>
        </div>
      </div>
    </div>


    <div (click)="addItemComment()" class="cart-items-container order-notes-container" *ngIf="!isReadOnly()">
      <small>
        <p class="notes-button">{{ 'ORDER.NOTES' | translate }}</p>
      </small>
      <div class="order-note" *ngIf="orderNote">
        <p>{{orderNote}}</p>
      </div>
    </div>

    <div class="cart-items-container" *ngIf="!voucherSubmitted && !isReadOnly()">
      <small>
        <p class="notes-button" (click)="openVoucherModal()">{{ 'VOUCHER.ADD' | translate }}</p>
      </small>
    </div>

    <p class="order-message" *ngIf="orderMessage">{{ orderMessage }}</p>
  </div>

  <div>
    <div *ngIf="showMinOrderMessage()" class="min-order" translate [translateParams]="{'currency': currency, 'amount': minOrder - subtotal}">
      COMMON.ADD_MIN_ORDER
    </div>

    <!-- Voucher -->
    <div class="total-details" *ngIf="totalQuantity > 0">

      <!-- subtotal (price of items) -->
      <div class="total" *ngIf="fees?.length > 0 || appliedVoucher?.amount">
        <span class="label">
          {{ 'ORDER.SUBTOTAL' | translate }}
        </span>
        <span class="value">
          {{ subtotal | currencySpace }}
          <div class="default-currency">
            {{ subtotal | currencySpace:true }}
          </div>
        </span>
      </div>

      <div *ngIf="appliedVoucher?.amount && appliedVoucher?.mode !== VoucherMode.Balance" class="total">
        <span class="label voucher-total">
          {{ 'VOUCHER.VOUCHER' | translate }}
        </span>
        <span class="value voucher-total">
          - {{ appliedVoucher.amount | currencySpace }}
        </span>
      </div>

      <div *ngIf="appliedVoucher?.amount && appliedVoucher?.mode === VoucherMode.Balance" class="total">
        <span class="label voucher-total">
          {{ 'VOUCHER.CURRENT_BALANCE' | translate }}
        </span>
        <span class="value voucher-total">
          {{ appliedVoucher.amount | currencySpace }}
        </span>
      </div>

      <!-- Fees -->
      <div *ngIf="fees?.length > 0">
        <div class="total" *ngFor="let fee of fees">
          <span class="label voucher-total">
            {{ fee.name }}
          </span>
          <span class="value voucher-total">
            {{ fee.amount | currencySpace }}
            <div class="default-currency">
              {{ fee.amount | currencySpace:true }}
            </div>
          </span>
        </div>
      </div>

      <!-- Final total to be paid -->
      <div class="total" *ngIf="finalTotal">
        <span class="label">
          {{ 'CART.TOTAL' | translate }}
        </span>
        <span class="value">
          {{ finalTotal | currencySpace }}
          <div class="default-currency">
            {{ finalTotal | currencySpace:true }}
          </div>
        </span>
      </div>

      <div *ngIf="appliedVoucher?.amount && appliedVoucher?.mode === VoucherMode.Balance">
        <hr>
        <div *ngIf="isOrderFree" class="total">
          <span class="label">
            {{ 'VOUCHER.REMAINING_BALANCE' | translate }}
          </span>
          <span class="value">
            {{ appliedVoucher.amount - subtotal | currencySpace }}
          </span>
        </div>
        <div *ngIf="!isOrderFree" class="total">
          <span class="label">
            {{ 'VOUCHER.AMOUNT_DUE' | translate }}
          </span>
          <span class="value">
            {{ finalTotal | currencySpace }}
          </span>
        </div>
      </div>

      <form *ngIf="!isReadOnly()" [formGroup]="form" class="bottom" (ngSubmit)="submit()">
        <button class="order-button" type="submit" mat-raised-button [disabled]="submitted">
          <span>
            {{ 'CART.PLACE_ORDER' | translate }}
          </span>
        </button>
      </form>
    </div>
  </div>
</div>

