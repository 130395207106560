import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

export interface RejectedItem {
  id: string;
  name: string;
}

@Component({
  selector: 'app-rejected-items',
  templateUrl: './rejected-items.component.html',
  styleUrls: ['./rejected-items.component.scss']
})
export class RejectedItemsComponent {

  public items: RejectedItem[];
  public modifiers: RejectedItem[];

  constructor(
    public dialogRef: MatDialogRef<RejectedItemsComponent>,
    // eslint-disable-next-line  @typescript-eslint/no-explicit-any
    @Inject(MAT_DIALOG_DATA)  public data: any,
  ) {
    this.items = this.data.items;
    this.modifiers = this.data.modifiers;
  }

  public close(): void {
    this.dialogRef.close();
  }

}
