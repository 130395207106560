<div class="buttons">
  <div class="buttons-container" [ngClass]="{'actions-no-bg': !hasActions}">
    <img class="menu-button" (click)="goMenu()" [src]="'menu_v2.svg' | themeSource" alt="menu"/>
    <div class="all-buttons" *ngFor="let action of menuActions; let i = index">
      <img [src]="getActionIcon(action) | themeSource" (click)="clickAction(action)" [alt]="action"/>
      <img *ngIf="i !== menuActions.length - 1" [src]="'line.svg' | themeSource" alt="dots"/>
    </div>
  </div>
</div>

