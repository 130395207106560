import { Injectable, EventEmitter } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SuperSectionService {

  public menuEmitter: EventEmitter<boolean> = new EventEmitter();

  public openMenu(): void {
    this.menuEmitter.emit(true);
  }

}
