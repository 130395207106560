import { Component, Inject, OnInit, OnDestroy } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DOCUMENT } from '@angular/common';
import { ApiService, PaymentMode, VenueType, Zone, Config, MenuClosedResponse } from './services/api.service';
import { DataService } from './services/data.service';
import { UserIdService } from './services/userid.service';
import { ScheduleService } from './services/schedule.service';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '../environments/environment';
import { RescanService } from './services/rescan.service';
import { Subscription } from 'rxjs';
import { AngularFireAnalytics } from '@angular/fire/compat/analytics';
import { SplashScreenService } from './services/splash-screen.service';
import { DomainService } from './services/domain.service';
import { OrderService } from './services/order.service';
import { StorageService, StoredItem } from './services/storage.service';
import { MenuService } from './menu/menu.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  public isRtl: boolean;
  public lang: string;

  public splashDisabled = false;

  private languageSub = Subscription.EMPTY;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private snackBar: MatSnackBar,
    private apiService: ApiService,
    private dataService: DataService,
    private scheduleService: ScheduleService,
    private userIdService: UserIdService,
    private translateService: TranslateService,
    private rescanService: RescanService,
    private analytics: AngularFireAnalytics,
    private splashScreenService: SplashScreenService,
    private domainService: DomainService,
    private orderService: OrderService,
    private storageService: StorageService,
    private menuService: MenuService
  ) {
  }

  public ngOnInit(): void {
    // This block HAS to be there to kickstart the app, otherwise you
    // will just get a blank page, go figure...
    this.translateService.setDefaultLang('en');
    this.translateService.use('en');

    this.disableAnalytics();
    this.checkUserId();
    this.loadData();
  }

  public ngOnDestroy(): void {
    this.languageSub.unsubscribe();
  }

  private checkUserId(): void {
    if (this.userIdService.getUserId() === '') {
      this.userIdService.setUserId();
    }
    this.dataService.setUserId(this.userIdService.getUserId());
  }

  private loadData(): void {
    // Note: This is like anti-angular but whatever, no time to fix bug that should not exist
    let tableId: string|null = this.getTableIdFromQueryString(window.location.search);
    if (window.location.hostname === 'localhost' && tableId === null) {
      tableId = 'fd17dd02-09d7-4c7c-809c-3be7d8e6869c';
    }
    if (tableId === null) {
      console.error('Could not find table, no param table_id given');
      this.rescanService.openRescan();
      return;
    }

    // TODO: dirty way of disabling splash screen after payment confirmed
    if (window.location.search.includes('merchant_ref')) {
      this.splashDisabled = true;
    }

    const ezetapMachineId = this.getFromQueryString(window.location.search, 'ezetap_device_id');

    if (ezetapMachineId) {
      this.dataService.setEzetapMachineId(ezetapMachineId);
    }

    this.apiService
      .getConfig(tableId)
      .subscribe(response => {
        this.setConfig(response);
        this.setTableAndRestaurant(tableId as string);
      }, (error) => {
        if (error.status === 403) {
          const menuClosed: MenuClosedResponse = error.error;
          this.splashScreenService.showSplash(menuClosed.image, menuClosed.languages, true);
        } else {
          window.location.href = 'https://opaala.com';
        }
      });
  }

  private setTableAndRestaurant(tableId: string): void {
    // This is a list of domains for which we will NOT enable the timer
    const domainsWithoutTimer = ["elite", "staytus"];

    this.apiService
      .getTableAndRestaurant(tableId)
      .subscribe(response => {
        this.dataService.setTable(response.table);
        this.dataService.setRestaurant(response.restaurant);
        this.validateNI(window.location.search, response.restaurant.payment_mode);
        this.vaildatePayment(window.location.search, response.restaurant.payment_mode);
        if (response.restaurant.type === VenueType.Default
          && response.restaurant.payment_mode === PaymentMode.Postpaid
          && ! domainsWithoutTimer.includes(this.domainService.getSubdomain())
        ) {
          this.rescanService.startTimer();
        }
        this.setVoucher();
        this.setMenu(response.zone);
      });
  }

  private setMenu(zone: Zone): void {
    this.dataService.setZone(zone);
    this.apiService
      .getMenuCached(zone.id)
      .subscribe(responseMenu => {
        this.scheduleService.registerSchedules(responseMenu);
      }, () => {
        // If there is an error we try again
        this.apiService.getMenuCached(zone.id).subscribe(responseMenu => {
          this.scheduleService.registerSchedules(responseMenu);
        });
      });
  }

  private setConfig(config: Config): void {
    if (!this.splashDisabled) {
      this.splashScreenService.showSplash(config.splash_screen);
    } else {
      this.splashScreenService.disableSplash();
    }
    this.setTranslation(config.languages);
    if (config.payment_methods.length > 0) {
      this.dataService.setPaymentMethods(config.payment_methods);
    }
    if (config.languages.length > 1 && !config.has_multiple_currencies) {
      this.dataService.hasMultiLanguage$.next(true);
    } else if (config.has_multiple_currencies) {
      this.dataService.hasMultiCurrency$.next(true);
    }
    if (config.has_filters) {
      this.dataService.hasFilters$.next(true);
    }
    if (config.grid_layout) {
      this.dataService.isGridLayout$.next(true);
    }
    if (config.promotions?.length > 0) {
      this.dataService.setPromotions(config.promotions);
    }
    if (config.fees?.length > 0) {
      this.dataService.hasFees$.next(true);
    }
    this.dataService.setMenuActions(config.menu_actions);
  }

  /**
   * validateNi
   * This method is to validate the payment for Network International.
   * After a redirection, the query string will contain: table_id, transaction_id,
   * resultIndicator and sessionVersion.
   * We need to make an api call with transaction_id and resultIndicator to confirm
   * whether the matching order has been really paid. The api returns 200 in case
   * of success (no body) or a series of 400 errors.
   */
  private validateNI(queryString: string, paymentMode: PaymentMode): void {
      const transactionId = this.getFromQueryString(queryString, 'transaction_id');
      const refCode = this.getFromQueryString(queryString, 'resultIndicator');
      if (transactionId === null || refCode === null) {
        return;
      }

      const niValidationPayload = {transaction_id: transactionId, ref_code: refCode};

      this.apiService
        .validateNiTransaction(niValidationPayload)
        .subscribe(
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            (response) => {
              if (paymentMode === PaymentMode.Prepaid) {
                this.orderService.setLastOrder();
              } else {
                this.orderService.setLastTransaction(transactionId);
              }
            }, (error) => {
              console.log(error);
            }
        );
  }

  private vaildatePayment(queryString: string, paymentMode: PaymentMode): void {
    const paymentToken = this.getFromQueryString(queryString, 'payment_token');
    const transactionId = this.getFromQueryString(queryString, 'transaction_id');

    if (paymentToken === null) {
      return;
    } else if (paymentToken === 'success') {
      if (paymentMode === PaymentMode.Postpaid && transactionId) {
        this.orderService.setLastTransaction(transactionId);
      } else {
        this.orderService.setLastOrder();
      }
    } else if (paymentToken === 'fail') {
      this.snackBar.open(
        this.translateService.instant('PAYMENT.ERROR'),
        this.translateService.instant('CART.GOT_IT'),
        {
          duration: 3500,
          panelClass: 'custom-snackbar'
        }
      );
    }
  }

  private getTableIdFromQueryString(queryString: string): string|null {
    return this.getFromQueryString(queryString, 'table_id');
  }

  private setVoucher(): void {
    const voucherName = this.getFromQueryString(window.location.search, 'voucher_code');
    if (!voucherName) {
      return;
    }
    this.dataService.setVoucherName(voucherName);
  }

  private getFromQueryString(queryString: string, key: string): string|null {
    // We remove the '?' from the query string
    const query: string = queryString.substr(1);

    // We get the individual parameters
    const splitted: string[] = query.split('&');

    // Searching for the index where the key is present
    const index = splitted.findIndex(elem => elem.startsWith(key));

    // index is -1, nothing found
    if (index === -1) {
      return null;
    }

    // We retrieve the couple "key=value"
    const couple = splitted[index];

    // And then we return the value (key [0] and value [1])
    return couple.split('=')[1];
  }

  private setTranslation(languages: string[]): void {
    this.translateService.addLangs(languages);
    this.lang = languages[0] || 'en';

    const storedLang = this.storageService.get(StoredItem.Language);
    if (!storedLang || !languages.find(l => l === storedLang)) {
      this.storageService.remove(StoredItem.Language);
    } else if (languages.find(l => l === storedLang)) {
      this.lang = storedLang;
    }

    this.translateService.use(this.lang);
    this.isRtl = this.isRtlLanguage();
    this.handleRtl();
    this.document.documentElement.lang = this.translateService.currentLang;
    this.menuService.languagesAdded$.next(true);
    this.languageSub = this.translateService.onLangChange.subscribe((langEvent) => {
      this.splashScreenService.showSplash('');
      this.lang = langEvent.lang;
      this.isRtl = this.isRtlLanguage();
      this.handleRtl();
      this.setTableAndRestaurant(this.dataService.getTable().id);
      this.document.documentElement.lang = this.lang;
    });
  }

  private isRtlLanguage(): boolean {
    return this.lang === 'ar' || this.lang === 'fa';
  }

  private handleRtl(): void {
    if (this.isRtl) {
      this.document.documentElement.classList.replace('ltr', 'rtl');
    } else {
      this.document.documentElement.classList.replace('rtl', 'ltr');
    }
  }

  private disableAnalytics(): void {
    if (!environment.production) {
      this.analytics.setAnalyticsCollectionEnabled(false);
    }
  }
}
