/* eslint-disable  @typescript-eslint/no-explicit-any */
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Section } from '../../services/api.service';
import { Subscription } from 'rxjs';
import { MenuService } from '../menu.service';
import { ScrollSpyService } from '../../services/scroll-spy.service';
import { CartService } from '../../services/cart.service';
import { DialogService } from '../../services/dialog.service';
import { animate, group, state, style, transition, trigger } from '@angular/animations';


@Component({
  selector: 'app-super-section',
  templateUrl: './super-section.component.html',
  styleUrls: ['./super-section.component.scss'],
  animations: [
    trigger('slideInOut', [
      state('in', style({
          'max-height': '500px', opacity: '1', visibility: 'visible'
      })),
      state('out', style({
          'max-height': '0px', opacity: '0', visibility: 'hidden'
      })),
      transition('in => out', [group([
        animate('50ms ease-in-out', style({
          visibility: 'hidden',
          opacity: '0'
        }))
      ]
      )]),
      transition('out => in', [group([
          animate('1ms ease-in-out', style({
              visibility: 'visible'
          })),
          animate('600ms ease-in-out', style({
              'max-height': '500px'
          })),
          animate('800ms ease-in-out', style({
              opacity: '1'
          }))
      ]
      )])
  ]),
  ]
})
export class SuperSectionComponent implements OnInit, OnDestroy {

  public currentSection?: Section;
  public hasSubsections = false;
  public subSectionsFilter?: string[];
  public activeSubsection?: string;
  public totalPrice = 0;
  public quantity = 0;
  public animationState = 'out';
  public searchOpened = false;

  private currentSectionSub = Subscription.EMPTY;
  private scrollSpySub = Subscription.EMPTY;
  private cartSub = Subscription.EMPTY;
  private searchSub = Subscription.EMPTY;

  constructor(
    private menuService: MenuService,
    private scrollSpyService: ScrollSpyService,
    private cartService: CartService,
    private dialogService: DialogService,
  ) { }

  ngOnInit(): void {
    this.currentSectionSub = this.menuService.currentSection$
      .subscribe(section => {
        this.currentSection = section;
        if (section.sub_sections?.length > 0) {
          this.hasSubsections = true;
          this.activeSubsection = section.sub_sections[0].name;
          this.scrollSpySub = this.scrollSpyService
            .sectionEmitter
              .subscribe(activeSection => {
                this.scrollHorizontal(activeSection);
            });
        } else {
          this.hasSubsections = false;
        }
      });

    this.getTotalPrice();
    this.cartSub = this.cartService.cartUpdated.subscribe(() => this.getTotalPrice());

    this.searchSub = this.menuService.searchOpened.subscribe(opened => {
      this.searchOpened = opened;
    });
  }

  ngOnDestroy(): void {
    this.currentSectionSub.unsubscribe();
    this.scrollSpySub.unsubscribe();
    this.cartSub.unsubscribe();
    this.searchSub.unsubscribe();
  }

  public onSectionChange(sectionName: string): void {
    this.scrollSpyService.setSection(sectionName);
  }

  public scrollVertical(id: string): void {
    const element: any = document.getElementById(id);
    // Happens when there are no sub sections
    if (element === null) {
      return;
    }

    window.scroll({
      behavior: 'smooth',
      left: 0,
      top: element.offsetTop - this.getTopBarHeight()
    });
  }

  public openCart(): void {
    this.dialogService.openCart();
  }

  private scrollHorizontal(id: string): void {
    const parent: any = document.getElementsByClassName('scrollable')[0];
    const element: any = document.getElementById(`item-${id}`);

    if (element) {
      parent.scrollTo({
        behavior: 'smooth',
        left: element.offsetLeft - element.offsetWidth,
        top: 0
      });
    }

    this.activeSubsection = id;
  }

  private getTopBarHeight(): number {
    const els = window.document.getElementsByClassName('fixed-top');
    if (els.length === 0) {
      return 100;
    }
    return els[0].clientHeight + 200;
  }

  private getTotalPrice(): void {
    const totalQuantity = this.cartService.getCart().cartLines.reduce((accumulator, cartLine) => accumulator + cartLine.quantity, 0);

    const lines = this.cartService.getCart().cartLines.map(cartLine => {
      const priceModifiers: number = cartLine.modifiers.reduce((acc, modifier) => acc + modifier.price, 0);

      return {
        id: cartLine.id,
        itemName: cartLine.item.name,
        quantity: cartLine.quantity,
        modifiers: cartLine.modifiers,
        total: (cartLine.item.price + priceModifiers) * cartLine.quantity
      };
    });

    this.quantity = totalQuantity;

    this.totalPrice = lines.reduce((accumulator, cartLine) => accumulator + cartLine.total, 0);

    this.animationState = this.quantity > 0 ? 'in' : 'out';
  }
}
