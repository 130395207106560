import { Component, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { environment } from '../../environments/environment';
import { MenuService } from '../menu/menu.service';
import { GetOrder, OrderStatus } from '../services/api.service';
import { OrderService } from '../services/order.service';

@Component({
  selector: 'app-order-confirmation',
  templateUrl: './order-confirmation.component.html',
  styleUrls: ['./order-confirmation.component.scss']
})
export class OrderConfirmationComponent implements OnDestroy {

  public order: GetOrder;
  public showReceiptButton: boolean;
  public orderMessage: string;

  private closed = false;
  private orderMessageSub = Subscription.EMPTY;

  constructor(
    private orderService: OrderService,
    private router: Router,
    private menuService: MenuService,
  ) {
    this.order = this.orderService.getLastOrder();
    if (!this.order) {
      this.close();
    }

    if (this.order.payment_type.is_online && this.order.status === OrderStatus.Paid && this.order.total_price > 0) {
      this.showReceiptButton = true;
    }

    this.orderMessageSub = this.menuService.orderMessage$
      .subscribe(message => {
        this.orderMessage = message;
      });

    setTimeout(() => this.close(), 10000);
  }

  public ngOnDestroy(): void {
    this.orderMessageSub.unsubscribe();
  }

  public close(): void {
    if (this.closed) {
      return;
    }
    this.closed = true;
    this.router.navigate(['/menu', {openMenu: true}]);
  }

  public openReceipt(): void {
    const url = `${environment.urls.api}/receipt/order/${this.order.order_id}.pdf`;
    window.open(url, '_blank');
  }
}
