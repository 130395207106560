import { Injectable, Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'paymentTypes'
})
@Injectable({
  providedIn: 'root'
})
export class PaymentTypesPipe implements PipeTransform {

  transform(key: string): unknown {
    let iconName: string;
    if (key === 'applepay') {
      iconName = 'applepay.svg';
    } else if (key === 'card') {
      iconName = 'card.svg';
    } else if (key === 'cash') {
      iconName = 'cash.svg';
    } else if (key === 'room') {
      iconName = 'room.svg';
    } else {
      iconName = 'online.svg';
    }
    return iconName;
  }
}
