<app-top-bar title="MENU.FILTERS" enableClose="false" isDialog="true" (closed)="close()">
</app-top-bar>

<div class="content">
  <div class="filters">
    <mat-checkbox *ngFor="let filter of filters" [(ngModel)]="filter.isActive" (change)="changeSelection($event.checked, filter)">
      {{filter.name}}
    </mat-checkbox>
  </div>

  <div class="buttons">
    <button *ngIf="filters.length" mat-raised-button class="confirm" (click)="confirm()">
      {{ 'ACTIONS.CONFIRM' | translate }}
    </button>
    <button *ngIf="filters.length" mat-raised-button class="cancel" (click)="clearSelections()">
      {{ 'ACTIONS.CLEAR_ALL' | translate }}
    </button>
  </div>
  <span class="footer">
    <img [src]="'opaala.svg' | themeSource" alt="opaala"/>
  </span>
</div>
