import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

// Components
import { MenuComponent } from './menu/menu.component';
import { FiltersComponent } from './filters/filters.component';
import { SuperSectionsListComponent } from './super-sections-list/super-sections-list.component';
import { SectionComponent } from './section/section.component';
import { ModifierComponent } from './modifier/modifier.component';
import { ItemComponent } from './item/item.component';
import { UseCartComponent } from './use-cart/use-cart.component';
import { SuperSectionComponent } from './super-section/super-section.component';
import { BottomBarComponent } from '../layout/bottom-bar/bottom-bar.component';
import { SuperSectionsGridComponent } from './super-sections-grid/super-sections-grid.component';
import { SuperSectionsContainerComponent } from './super-sections-container/super-sections-container.component';

// Directives
import { ScrollSpyDirective } from '../shared/directives/scroll-spy.directive';

import { MenuRoutingModule } from './menu-routing.module';
import { SharedModule } from '../shared/shared.module';
import { PromotionsComponent } from './promotions/promotions.component';

@NgModule({
  declarations: [
    MenuComponent,
    FiltersComponent,
    SuperSectionsListComponent,
    SectionComponent,
    ModifierComponent,
    ItemComponent,
    UseCartComponent,
    BottomBarComponent,
    ScrollSpyDirective,
    SuperSectionComponent,
    SuperSectionsGridComponent,
    SuperSectionsContainerComponent,
  ],
  imports: [
    CommonModule,
    MenuRoutingModule,
    SharedModule,
    PromotionsComponent
  ]
})

export class MenuModule { }
