import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { DataService } from '../../services/data.service';

@Component({
  selector: 'app-super-sections-container',
  templateUrl: './super-sections-container.component.html',
  styleUrls: ['./super-sections-container.component.scss']
})
export class SuperSectionsContainerComponent implements OnInit, OnDestroy {
  public isGridLayout: boolean;
  public openMenu = false;

  private gridLayoutSub = Subscription.EMPTY;

  constructor(
    private dataService: DataService,
    private route: ActivatedRoute,
  ) {
    this.openMenu = this.route.snapshot.paramMap.get('openMenu') === 'true'? true : false;
  }

  public ngOnInit(): void {
    this.gridLayoutSub = this.dataService.isGridLayout$
      .subscribe(res => {
        this.isGridLayout = res;
      });
  }

  public ngOnDestroy(): void {
    this.gridLayoutSub.unsubscribe();
  }

}
