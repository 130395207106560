
<div class="payment-link-container">
  <div class="top">
    <img [src]="'order_confirmed.svg' | themeSource" />
    <span class="text">Payment Link Sent</span>
  </div>

  <div class="buttons">
    <button mat-raised-button class="close" (click)="close()">
      New Order
    </button>
  </div>
</div>
