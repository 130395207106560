import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-use-cart',
  templateUrl: './use-cart.component.html',
  styleUrls: ['./use-cart.component.scss']
})
export class UseCartComponent {

  constructor(
    public dialogRef: MatDialogRef<UseCartComponent>,
  ) {}

  public confirm(): void {
    this.dialogRef.close();
  }

}
