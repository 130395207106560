import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ApiService, CurrencyRate } from './api.service';
import { DataService } from './data.service';

@Injectable({
  providedIn: 'root'
})
export class CurrencyService {
  public currencies$ = new BehaviorSubject<string[]>([]);
  public currentCurrency: string;
  public defaultCurrency: string;

  private conversionMap = new Map<string, number>();

  constructor(
    private apiService: ApiService,
    private dataService: DataService
  ) {
    this.defaultCurrency = this.dataService.getRestaurant()?.currency;
    this.currentCurrency = this.defaultCurrency;
  }

  public setCurrencies(): void {
    const restaurantId: string = this.dataService.getRestaurant().id;
    this.apiService.getCurrencies(restaurantId)
      .subscribe(response => {
        if (response.length > 0) {
          // Add default Restaurant currency to the beginning of the list
          const defaultRate: CurrencyRate = {
            currency: this.defaultCurrency,
            rate: 1
          };
          response.unshift(defaultRate);
          this.conversionMap = new Map(response.map((i): [string, number] => [i.currency, i.rate]));
          this.currencies$.next(Array.from(this.conversionMap.keys()));
        }
    });
  }

  public setCurrentCurrency(currency: string) {
    this.currentCurrency = currency;
  }

  public currencyChanged(): boolean {
    return this.defaultCurrency !== this.currentCurrency;
  }

  public calculatePrice(value: number, currency: string): number {
    const rate = this.conversionMap.get(currency);
    if (rate) {
      return value / rate;
    } else {
      return value;
    }
  }
}
