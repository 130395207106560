<div id="comment">
  <p class="item-name">{{ data.line?.itemName || 'ORDER.NOTES' | translate }}</p>
  <div class="special-notes">
    <div class="textarea-container">
      <textarea [placeholder]="'WAITER.ADDITIONAL_COMMENTS' | translate" [(ngModel)]="comment" maxLength="{{charMax}}"></textarea>
    </div>
  </div>

  <div class="buttons">
    <button class="confirm" mat-raised-button (click)="confirm()">{{ 'ACTIONS.SUBMIT' | translate }}</button>
  </div>
</div>
