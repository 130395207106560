<div *ngIf="orders.length === 0">
  <p class="empty">{{ 'ACTIONS.EMPTY' | translate }}</p>
</div>

<div class="order-list">
  <div class="order" *ngFor="let order of displayableOrders | keyvalue">
    <h4>
      <span>
        {{ order.value[0].order_details.code.substr(4) }} | {{ order.value[0].order_details.table.table_type }} {{ order.value[0].order_details.table.name }} | {{ order.value[0].order_details.created_at | date:'shortTime' }}
      </span>
      <span class="green" *ngIf="showPaid(order.value[0])">
        | {{ 'ORDER.PAID' | translate  }}
      </span>
      <span class="green" *ngIf="showConfirmed(order.value[0])">
        | {{ 'ORDER.CONFIRMED' | translate  }}
      </span>
    </h4>
    <div class="grouped-order" *ngFor="let order of order.value">
      <ul>
        <span class="order-section" *ngIf="isParent">{{ order.section }}:</span>
        <li *ngFor="let item of order.order_items">
          <div class="item-header">
            <span class="item-info" [ngClass]="{'striked': item.picked_up}">{{ item.quantity }} x {{ item.item }}</span>
            <span class="item-price">
              {{ item.total_price_without_discount | currencySpace }}
            </span>
          </div>
          <ul class="item-details" *ngIf="item.modifiers && item.modifiers.length > 0">
            <li *ngFor="let mod of item.modifiers">* <span *ngIf="mod.quantity > 1"> x{{ mod.quantity }} </span>{{ mod.modifier }} {{ mod.total_price | currencySpace }}</li>
          </ul>
          <ul class="item-details" *ngIf="item.comment">
            <li *ngIf="item.comment"> *{{item.comment}}</li>
          </ul>
        </li>
      </ul>
    </div>
  </div>
</div>
