import { Injectable, EventEmitter } from '@angular/core';
import { Item, Modifier } from './api.service';
import { v4 as uuidv4 } from 'uuid';
import { SelectedModifier } from '../menu/modifier/modifier.component';

export interface CartLine {
  id: string;
  item: Item;
  comment?: string;
  modifiers: Modifier[];
  quantity: number;
}

interface Cart {
  cartLines: CartLine[];
}

@Injectable({
  providedIn: 'root'
})
export class CartService {

  public emptyEmitter: EventEmitter<boolean> = new EventEmitter();
  public cartUpdated = new EventEmitter();

  private cart: Cart;

  constructor() {
    this.cart = {cartLines: []};
  }

  public getCart(): Cart {
    return this.cart;
  }

  public clearCart(): void {
    this.cart = {cartLines: []};
    this.emptyEmitter.emit(true);
    this.cartUpdated.emit();
  }

  public removeLine(cartLineId: string): void {
    const cartLine: CartLine | undefined = this.cart.cartLines.find(cLine => cLine.id === cartLineId);

    if (!cartLine) {
      return;
    }

    const modifiers: SelectedModifier[] = cartLine.modifiers.map(modifier => ({
      id: modifier.id,
      quantity: modifier.quantity
    }));
    this.updateCart(cartLine.item, modifiers, 0);
  }

  public incrementLine(cartLineId: string): void {
    const cartLine: CartLine | undefined = this.cart.cartLines.find(cLine => cLine.id === cartLineId);

    if (!cartLine) {
      return;
    }

    const modifiers: SelectedModifier[] = cartLine.modifiers.map(modifier => ({
      id: modifier.id,
      quantity: modifier.quantity
    }));

    this.updateCart(cartLine.item, modifiers, 1);
  }

  public decrementLine(cartLineId: string): void {
    const cartLine: CartLine | undefined = this.cart.cartLines.find(cLine => cLine.id === cartLineId);

    if (!cartLine) {
      return;
    }

    const modifiers: SelectedModifier[] = cartLine.modifiers.map(modifier => ({
      id: modifier.id,
      quantity: modifier.quantity
    }));

    this.updateCart(cartLine.item, modifiers, -1);
  }

  public addItemComment(cartLineId: string, comment: string): void {
    const cartLine: CartLine | undefined = this.cart.cartLines.find(cLine => cLine.id === cartLineId);

    if (!cartLine) {
      return;
    }

    cartLine.comment = comment;
    this.cartUpdated.emit();
  }

  public updateCart(item: Item, selectedModifiers: SelectedModifier[], quantity: number): void {
    const modifiers: Modifier[] = this.getModifiersFromId(item, selectedModifiers);
    const line: CartLine | undefined = this.findCartLine(item, selectedModifiers);

    if (line) {
      const lineIndex: number = this.cart.cartLines.findIndex(cartLine => line.id === cartLine.id);

      if (quantity === 0) {
        this.cart.cartLines.splice(lineIndex, 1);
      } else {
        this.cart.cartLines[lineIndex].quantity += quantity;

        // If the quantity becomes 0 after an operation
        if (this.cart.cartLines[lineIndex].quantity === 0) {
          this.cart.cartLines.splice(lineIndex, 1);
        }
      }
    } else {
      this.cart.cartLines.push({id: uuidv4(), item, modifiers, quantity});
    }

    if (this.cart.cartLines.length === 0) {
      this.emptyEmitter.emit(true);
    } else {
      this.emptyEmitter.emit(false);
    }
    this.cartUpdated.emit();
  }

  private getModifiersFromId(item, selectedMods: SelectedModifier[]): Modifier[] {
    const modifiers: Modifier[] = [];
    const allModifiers: Modifier[] = item.modifier_groups
    .reduce((accumulator, modifierGroup) => accumulator.concat(modifierGroup.modifiers), []);

    for (const selectedMod of selectedMods) {
      const modifier: Modifier | undefined = allModifiers.find((mod) => mod.id === selectedMod.id);
      if (modifier) {
        modifier.quantity = selectedMod.quantity;
        modifiers.push(modifier);
      }
    }
    return modifiers;
  }

  private findCartLine(item: Item, modifiers: SelectedModifier[]): CartLine|undefined {
    return this.cart.cartLines.find(cartLine => {
      const currentModifierIds: SelectedModifier[] = cartLine.modifiers
        .map(modifier => ({
          id: modifier.id,
          quantity: modifier.quantity
        })).sort();

      // TODO: this is a lazy approach
      const sameArray: boolean = JSON.stringify(modifiers.sort()) === JSON.stringify(currentModifierIds);
      return item.id === cartLine.item.id && sameArray;
    });
  }

}
