import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-comment-dialog',
  templateUrl: './comment-dialog.component.html',
  styleUrls: ['./comment-dialog.component.scss']
})
export class CommentDialogComponent implements OnInit {

  public comment: string;
  public charMax: number;

  constructor(
    public dialogRef: MatDialogRef<CommentDialogComponent>,
    // eslint-disable-next-line  @typescript-eslint/no-explicit-any
    @Inject(MAT_DIALOG_DATA)  public data: any,
  ) {
    this.charMax = this.data.line ? 150 : 250;
  }

  public ngOnInit(): void {
    if (this.data?.line) {
      this.comment = this.data.line.comment;
    } else if (this.data?.orderNote) {
      this.comment = this.data.orderNote;
    }
  }

  public confirm(): void {
    if (!this.comment) {
      return;
    }
    this.dialogRef.close({
      comment: this.comment
    });
  }

}
