import { Injectable, EventEmitter } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { ItemFilter, Section, Menu, Item } from '../services/api.service';
import { BehaviorSubject, ReplaySubject } from 'rxjs';
import { SplashScreenService } from '../services/splash-screen.service';
import { Router } from '@angular/router';
import { SuperSectionService } from '../services/super-section.service';
import { DataService } from '../services/data.service';
import { StorageService, StoredItem } from '../services/storage.service';

export interface ActivableSection extends Section {
  isActive: boolean;
  banner_url: string;
  logo_url: string;
  sub_sections: ActivableSection[];
}

export interface DisplayableSection {
  id: string;
  name: string;
  description: string;
  logo_url: string | undefined;
}

@Injectable({
  providedIn: 'root'
})
export class MenuService {
  public superSections$: ReplaySubject<ActivableSection[]> =  new ReplaySubject(1);
  public displayableSuperSections$: ReplaySubject<(DisplayableSection | undefined)[]> = new ReplaySubject(1);
  public currentSection$: ReplaySubject<Section> = new ReplaySubject(1);
  public description$: ReplaySubject<string> = new ReplaySubject(1);
  public orderMessage$: ReplaySubject<string> = new ReplaySubject(1);
  public languagesAdded$: BehaviorSubject<boolean> = new BehaviorSubject(false);

  public searchForm = new FormGroup({
    search: new FormControl('')
  });

  public gridSearch = new FormGroup({
    search: new FormControl('')
  });

  public filtersEvent = new EventEmitter<ItemFilter[]>();
  public searchOpened: EventEmitter<boolean> = new EventEmitter();

  /*
    ReplaySubjects with buffer size set to 1 are basically the same as BehaviorSubjects with no initial value.
    I've chose to use them here to skip the initial null value and wait for the menu to load
  */
  private menu$: ReplaySubject<Menu> = new ReplaySubject(1);
  private currentMenu: Menu;

  private allItems: Item[] = [];
  private allSections: ActivableSection[] = [];
  private allDisplayableSections: DisplayableSection[] = [];
  private filteredItems: Item[];
  private currentSectionBanner: string;

  constructor(
    private splashScreenService: SplashScreenService,
    private router: Router,
    private superSectionService: SuperSectionService,
    private dataService: DataService,
    private storageService: StorageService,
  ) {
    this.setSuperSections();
    this.setSectionNames();
    this.setMenuInfo();
  }

  public setMenu(menu: Menu): void {
    // This is the entry point of all the chain of subscriptions of the menu sections and items, so unless
    // A section or item was updated by a schedule, we would not trigger any subs.
    if (this.currentMenu !== menu) {
      this.currentMenu = menu;
      this.menu$.next(menu);
      // Get a flat list of all items for the global search.
      this.allItems = this.getAllItems(menu);
    }
  }

  public setSuperSections(): void {
    this.menu$.subscribe(menu => {
      this.superSections$.next(menu.sections);
    });
  }

  // Description, Order Message....
  public setMenuInfo(): void {
    this.menu$.subscribe(menu => {
      this.description$.next(menu.description);
      this.orderMessage$.next(menu.order_message);
    });
  }

  public setSectionNames(): void {
    this.superSections$.subscribe(superSections => {
      this.allSections = Object.assign([], superSections);
      const sections: DisplayableSection[] = superSections.map(({id, name, logo_url, description }) => ({id, name, logo_url, description}));
      this.displayableSuperSections$.next(sections);
      this.allDisplayableSections = sections;
      this.openSuperSectionList(sections);
      this.splashScreenService.hideSplash();
    });
  }

  public setCurrentSectionById(id: string): void {
    const sectionName = this.getSectionById(id);
    this.router.navigate(['/menu', encodeURIComponent(sectionName)]);
    this.setCurrentSectionByName(sectionName);
  }

  public setCurrentSectionByName(name?: string): void {
    this.superSections$.subscribe(sections => {
      if (!name) {
        this.router.navigate(['/menu', encodeURIComponent(sections[0].name)]);
        this.currentSectionBanner = sections[0].banner_url || '';
      } else if (name === 'search') {
        const filteredSection = Object.assign({}, sections[0]);
        filteredSection.name = 'search';
        filteredSection.description = '';
        filteredSection.items = this.filteredItems ? this.filteredItems : [];
        filteredSection.sub_sections = [];
        this.currentSection$.next(filteredSection);
      } else {
        const section = sections.find(s => s.name === name);
        // TODO: pass index instead of name to stay on same index when changing language.
        if (!section) {
          this.setCurrentSectionByName();
        } else {
          this.currentSectionBanner = section.banner_url || '';
          this.currentSection$.next(section);
        }
      }
    });
  }

  public applyGlobalSearch(query: string): void {
    this.filteredItems = [];
    this.filteredItems = this.getSearchItems(query);
    this.setCurrentSectionByName('search');
  }

  // Searching for Items
  public getSearchItems(query: string): Item[] {
    if (query) {
      const q = query.toLowerCase();

      // We search for items in a specific order: by 1. name -> 2. description -> 3. modifiers and category
      const filteredItemsByName = this.allItems.filter(x =>
        x.name.toLowerCase().indexOf(q) >= 0
      );
      const filteredItemsByDescription = this.allItems.filter(x =>
        x.description.toLowerCase().indexOf(q) >= 0
      );
      const filteredItemsByModifiers = this.allItems.filter(x =>
        x.modifier_groups.filter(mg => (mg.modifiers.map(m => m.name.toLowerCase()).join(';').indexOf(q) >= 0)).length > 0
        || x.category.toLowerCase().indexOf(q) >= 0
      );

      // Then we conact all 3 lists (which preserves the order) and remove dupes
      let items = filteredItemsByName.concat(filteredItemsByDescription.filter((item) => filteredItemsByName.indexOf(item) < 0));
      items = items.concat(filteredItemsByModifiers.filter((item) => items.indexOf(item) < 0));
      return items;
    } else {
      return [];
    }
  }

  // Searching for sections
  public getSearchSections(query: string): DisplayableSection[] {
    if (query) {
      const q = query.toLocaleLowerCase();
      // These are sections which match by name;
      const filteredSectionsByName = this.allDisplayableSections.filter(x =>
        x.name.toLocaleLowerCase().indexOf(q) >= 0
      );

      // These are the sections which contain an item we are searching for
      const filteredSectionsByItem = this.getSearchSectionsByItem(query);

      // concat both sections and remove dupes
      let allFilteredSections = filteredSectionsByName.concat(filteredSectionsByItem);
      allFilteredSections = allFilteredSections.filter((value, index, self) =>
        index === self.findIndex((t) => (
          t.id === value.id
        ))
      );
      return allFilteredSections;
    } else {
      return [];
    }
  }

  public getItemById(id: string): Item {
    return this.allItems.filter(item => item.id === id)[0];
  }

  public getSuperSectionBanner(): string {
    return this.currentSectionBanner;
  }

  private getSectionById(id: string): string {
    return this.allSections.filter(section => section.id === id)[0].name;
  }

  private getAllItems(menu: Menu): Item[] {
    const ids: string[] = [];
    const items: Item[] = [];

    menu.sections.forEach((section: ActivableSection) => {
      const alreadyExists: boolean = ids.indexOf(section.id) !== -1;
      if (!alreadyExists && section.isActive) {
        ids.push(section.id);
        menu.sections.push(section);
      }
      section.sub_sections.forEach((subSection: ActivableSection) => {
        const alreadySubExists: boolean = ids.indexOf(subSection.id) !== -1;
        if (! alreadySubExists && subSection.isActive) {
          ids.push(subSection.id);
          menu.sections.push(subSection);
        }
      });
    });

    menu.sections.forEach((section: Section) => {
      section.items.forEach((item: Item) => {
        const alreadyExists: boolean = ids.indexOf(item.id) !== -1;
        if (! alreadyExists) {
          ids.push(item.id);
          items.push(item);
        }
      });
    });
    return items;
  }

  // Returns the sections which an item we are searching for belongs to
  private getSearchSectionsByItem(query: string): DisplayableSection[] {
    if (query) {
      const sections: DisplayableSection[] = [];
      this.allSections.forEach(section => {
        const items = this.searchForItemsInSection(query, section);
        if (section.sub_sections.length > 0) {
          section.sub_sections.forEach(subSection => {
            const subItems = this.searchForItemsInSection(query, subSection);
            if (subItems.length > 0) {
              sections.push(section);
            }
          });
        }
        if (items.length > 0) {
          sections.push(section);
        }
      });
      const displayableSections: DisplayableSection[] = sections
        .map(({id, name, logo_url, description }) => ({id, name, logo_url, description}));
      return displayableSections;
    } else {
      return [];
    }
  }

  // search for items in one section
  private searchForItemsInSection(query: string, section: ActivableSection): Item[] {
    const q = query.toLowerCase();
    const items = section.items.filter(x =>
      x.name.toLowerCase().indexOf(q) >= 0 ||
      x.description.toLowerCase().indexOf(q) >= 0 ||
      x.modifier_groups.filter(mg => (mg.modifiers.map(m => m.name.toLowerCase()).join(';').indexOf(q) >= 0)).length > 0 ||
      x.category.toLowerCase().indexOf(q) >= 0 &&
      section.isActive
    );
    return items;
  }

  private openSuperSectionList(superSectionNames: DisplayableSection[]): void {
    // to handle the case if the order confirmation page loads faster than the menu.
    if (this.router.url !== '/confirm-order' && this.router.url !== '/bill-paid'){
      const selectedLanguage = this.storageService.get(StoredItem.Language);
      this.dataService.hasMultiLanguage$.subscribe(hasMultiLanguages => {
        if (!hasMultiLanguages || selectedLanguage) {
          if (superSectionNames.length > 1) {
            this.superSectionService.openMenu();
          } else {
            this.setCurrentSectionByName();
          }
        } else {
          // eslint-disable-next-line  @typescript-eslint/no-unused-vars
          this.languagesAdded$.subscribe(res => {
            this.router.navigate(['/select-language']);
          });
        }
      });
    }
  }
}
