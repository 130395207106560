import { Injectable, EventEmitter } from '@angular/core';

/* This service handles communication between scrollspy directives and component */
@Injectable({
  providedIn: 'root'
})
export class ScrollSpyService {

  public sectionEmitter: EventEmitter<string> = new EventEmitter();
  private section: string;


  public setSection(section: string): void {
    this.section = section;
    this.sectionEmitter.emit(section);
  }

  public getSection(): string {
    return this.section;
  }
}
