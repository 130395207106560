<app-top-bar title="PAYMENT.PAY_CARD" enableClose="false" isDialog="true" (closed)="close()">
</app-top-bar>

<div class="content">
  <div class="payment-summary">
    <p class="name">{{ 'PAYMENT.SUMMARY' | translate }}</p>

    <div class="total">
      <span class="label">
        {{ 'CART.TOTAL' | translate }}
      </span>
      <span class="value">
        {{ data.total_price_without_discount | currencySpace }}
        <div class="default-currency">
          {{ data.total_price_without_discount | currencySpace:true }}
        </div>
      </span>
    </div>

    <div *ngIf="hasVoucher" class="total">
      <span class="label voucher-total">
        {{ 'VOUCHER.VOUCHER' | translate }}
      </span>
      <span class="value voucher-total">
        - {{ data.discount_amount | currencySpace }}
      </span>
    </div>

    <div *ngIf="hasVoucher" class="total">
      <span class="label">
        {{ 'VOUCHER.DISCOUNTED_AMOUNT' | translate }}
      </span>
      <span class="value">
        {{ data.total_price | currencySpace }}
      </span>
    </div>
    <hr>
  </div>

  <form ngNoForm method="post" [action]="data.url">
    <div class="payment-form">
      <input type="hidden" name="merchantId" [value]="data.merchant_id"/>
      <input type="hidden" name="apiKey" [value]="data.api_key"/>
      <input type="hidden" name="command" value="tokenize"/>
      <input type="hidden" name="extraData1" [value]="data.transaction_id"/>
      <input type="hidden" name="tokenizev2" value="true"/>
      <mafpay-threeds-component *ngIf="threedsauthid" [threedsauthid]="threedsauthid"></mafpay-threeds-component>
      <mafpay-card-number></mafpay-card-number>
      <mafpay-card-holder-name></mafpay-card-holder-name>
      <mafpay-card-expiry></mafpay-card-expiry>
      <mafpay-card-cvc></mafpay-card-cvc>
      <mafpay-card-submit (click)="submitPayment()"></mafpay-card-submit>
    </div>
  </form>

  <div class="loader-container" *ngIf="isLoading">
    <mat-spinner></mat-spinner>
  </div>
</div>

