<app-top-bar></app-top-bar>

<div class="top">
  <img [src]="'order_confirmed.svg' | themeSource" />
  <span class="text">{{ 'PAYMENT.SUCCESSFUL' | translate }}</span>
  <span class="text-small">{{ transaction.orders.length }} {{ 'ORDER.ORDERS' | translate }} | {{ 'ORDER.PAID' | translate }} | {{ transaction.transaction.created_at | date:'shortTime' }}</span>
</div>

<div class="orders">
  <ul>
    <li *ngFor="let order of transaction.orders">
      <div class="order-header">
        <span class="order-info"><b>{{ 'ORDER.ORDER' | translate }} # {{ order.code.substr(4) }}</b> | {{ order.table.name }} | {{ order.created_at | date:'shortTime' }}</span>
        <span>{{ order.total_price | currencySpace}}</span>
      </div>
    </li>
  </ul>
</div>

<div class="total">
  <div class="row">
    <span class="label">{{ 'ORDER.TOTAL' | translate }}</span>
    <span class="amount">
      {{ total | currencySpace }}
      <div class="default-currency">
        {{ total | currencySpace:true }}
      </div>
    </span>
  </div>
</div>


<div class="buttons">
  <div class="receipt">
    <span class="receipt-text" (click)="openReceipt()">{{ 'ACTIONS.VIEW_RECEIPT' | translate }}</span>
    <img [src]="'receipt.svg' | themeSource" (click)="openReceipt()">
  </div>
  <button mat-raised-button class="close" (click)="close()">
    {{ 'ACTIONS.AWESOME' | translate }}
  </button>
</div>
