import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DomainService {

  public getSubdomain(): string {
    const currentDomain: string = window.location.hostname;

    const currentSubDomain: string = currentDomain.split('.').shift() || '';
    const previewDomainTLD: string = currentDomain.split('.').pop() || '';

    // This is to make it work in a local environment
    const subdomain = currentSubDomain === 'localhost' ||
      currentSubDomain.startsWith('app-') ||
      currentSubDomain.startsWith('staging-') ||
      previewDomainTLD.startsWith('dev') ?
        'c' : currentSubDomain;

    return subdomain;
  }

}
