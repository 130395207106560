import { ChangeDetectorRef, Component, Inject, OnDestroy } from '@angular/core';
import { MatBottomSheetRef, MAT_BOTTOM_SHEET_DATA } from '@angular/material/bottom-sheet';
import { Subscription } from 'rxjs';
import { ApiService, GetOrder, Order, OrderStatus, PaymentMethod } from '../../services/api.service';
import { ApplePayService } from '../../services/apple-pay.service';
import { DataService } from '../../services/data.service';
import { OnlinePaymentService } from '../../services/online-payment.service';
import { OrderService } from '../../services/order.service';
import { ApplePayConfig } from '../cart.component';

@Component({
  selector: 'app-payment-sheet',
  templateUrl: './payment-sheet.component.html',
  styleUrls: ['./payment-sheet.component.scss']
})
export class PaymentSheetComponent implements OnDestroy {

  public selectedPaymentType: PaymentMethod;
  public paymentTypes: PaymentMethod[];
  public isApplePay: boolean;
  public submitted = false;
  public isLoading = false;

  private orderId: string;
  private order: Order;
  private applepayConfig: ApplePayConfig;
  private tableId: string;

  private apiSub = Subscription.EMPTY;
  private applePaySub = Subscription.EMPTY;
  private paymentCancelledSub = Subscription.EMPTY;
  private sheetClosedSub = Subscription.EMPTY;

  constructor(
    // eslint-disable-next-line  @typescript-eslint/no-explicit-any
    @Inject(MAT_BOTTOM_SHEET_DATA) public data: any,
    private ref: MatBottomSheetRef<PaymentSheetComponent>,
    private onlinePaymentService: OnlinePaymentService,
    private changeDetectorRef: ChangeDetectorRef,
    private applePayService: ApplePayService,
    private apiService: ApiService,
    private dataService: DataService,
    private orderService: OrderService
  ) {
    this.selectedPaymentType = this.data.paymentType;
    this.tableId = this.dataService.getTable().id;
    this.paymentTypes = data.paymentTypes;
    this.applepayConfig = data.applepayConfig;
    this.order = data.order;
    // eslint-disable-next-line  @typescript-eslint/no-unused-vars
    this.sheetClosedSub = this.onlinePaymentService.paymentSheetClosed.subscribe(closed => {
      this.submitted = false;
      this.close();
    });
  }

  public ngOnDestroy(): void {
    this.apiSub.unsubscribe();
    this.applePaySub.unsubscribe();
    this.paymentCancelledSub.unsubscribe();
    this.sheetClosedSub.unsubscribe();
  }

  public setPaymentType(): void {
    if (this.selectedPaymentType.key === 'applepay') {
      this.isApplePay = true;
    } else {
      this.isApplePay = false;
    }
    this.order.payment_type = this.selectedPaymentType.key;
  }

  public done(): void {
    this.submitted = true;
    this.orderService.submitOrder(this.tableId, this.order).then(res =>{
      if (res.order) {
        this.orderId = res.order.order_id;
        if (this.selectedPaymentType.is_online && this.selectedPaymentType.key !== 'applepay') {
          this.isLoading = true;
          this.onlinePaymentService.startPayment([this.orderId]);
        }

        if (!this.selectedPaymentType.is_online) {
          this.ref.dismiss({
            order: res.order,
            paymentType: this.selectedPaymentType
          });
        }
      } else if (res.error?.modifers || res.error?.items) {
        this.ref.dismiss({
          error: res.error
        });
      }
    });

    this.paymentCancelledSub = this.onlinePaymentService.paymentCancelled.subscribe(cancelled => {
      if (cancelled) {
        this.submitted = false;
        this.isLoading = false;
        if (this.orderId) {
          // eslint-disable-next-line  @typescript-eslint/no-unused-vars
          this.apiSub = this.apiService.cancelOrder(this.orderId).subscribe(ok => this.orderId = '');
        }
        this.changeDetectorRef.detectChanges();
      }
    });
  }

  public close(): void {
    if (!this.submitted) {
      this.ref.dismiss();
    }
  }

  public submitApplePay(): void {
    if (this.submitted) {
      return;
    }

    this.submitted = true;
    // eslint-disable-next-line  @typescript-eslint/no-explicit-any
    const applePaySession = new (window as any).ApplePaySession(6, this.applepayConfig);
    let order: GetOrder;

    this.orderService.submitOrder(this.tableId, this.order).then(res =>{
      if (res.order) {
        order = res.order;
        this.orderId = res.order.order_id;
        this.applePayService.startApplePaySession(applePaySession, [this.orderId]);
        applePaySession.oncancel = () => {
          this.submitted = false;
          // eslint-disable-next-line  @typescript-eslint/no-unused-vars
          this.apiSub = this.apiService.cancelOrder(this.orderId).subscribe(ok => this.orderId = '');
          this.changeDetectorRef.detectChanges();
        };
      } else if (res.error?.modifers || res.error?.items) {
        this.ref.dismiss({
          error: res.error
        });
      }
    });

    this.applePaySub = this.applePayService.ApplePaySuccess.subscribe(state => {
      if (state) {
        order.status = OrderStatus.Paid;
        this.ref.dismiss({
          order,
          paymentType: this.selectedPaymentType
        });
      }
    });
  }
}
