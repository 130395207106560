import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';

export enum StoredItem {
  Language = 'LANGUAGE',
  OrderForm = 'USER-FORM-DATA'
}

@Injectable({
  providedIn: 'root'
})
export class StorageService {
  private readonly PREFIX = `OPAALA-${environment.production ? 'PROD' : 'STAGING'}`;

  public get(type: StoredItem): string | undefined {
    return window.localStorage.getItem(this.buildKey(type)) || undefined;
  }

  public set(type: StoredItem, value: string): void {
    return window.localStorage.setItem(this.buildKey(type), value);
  }

  public remove(type: StoredItem): void {
    return window.localStorage.removeItem(this.buildKey(type));
  }

  private buildKey(type: StoredItem): string {
    return `${this.PREFIX}-${type}`;
  }
}
