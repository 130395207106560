import { Directive, Input, EventEmitter, Output, ElementRef, OnInit } from '@angular/core';
import { fromEvent } from 'rxjs';
import { throttleTime } from 'rxjs/operators';

@Directive({
    selector: '[appScrollSpy]'
})
export class ScrollSpyDirective implements OnInit {

  @Input()
  public spiedTags = [];

  @Output()
  public sectionChange = new EventEmitter<string>();

  private currentSection = '';

  // throttleTime = 0 basically means we do not have any throttle on scroll
  private scrollListener = fromEvent(window, 'scroll').pipe(throttleTime(0));

  constructor(private _el: ElementRef) {}

  ngOnInit(): void {
    this.scrollListener.subscribe(() => this.onScroll());
  }

  private onScroll(): void {
    let curSection = '';
    const children = this._el.nativeElement.children;
    const scrollTop = window.innerHeight;
    const parentOffset = window.pageYOffset;

    for (const child of children) {
      if (this.spiedTags.some(spiedTag => spiedTag === child.tagName)) {
        if ((child.offsetTop - parentOffset) <= scrollTop / 2) {
          curSection = child.id;
        }
      }
    }

    if (curSection !== this.currentSection) {
      this.currentSection = curSection;
      this.sectionChange.emit(this.currentSection);
    }
  }
}
