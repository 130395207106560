<div class="supersections-grid-modal" [ngClass]="{'opened': menuOpened}">
  <!-- Topbar -->
  <div class="ss-grid-topbar">
    <span>{{ currentTable?.table_type }} {{ currentTable?.name }}</span>
    <img height="20px" width="20px" [src]="'close.svg' | themeSource" (click)="select()">
  </div>

  <!-- Logo -->
  <div *ngIf="logoUrl" class="ss-grid-logo-container">
    <div class="image logo" [ngStyle]="logoStyle"></div>
  </div>

  <!-- promotions -->
  <div *ngIf="promotions?.length > 0" class="ss-promotions-container">
    <app-promotions></app-promotions>
  </div>


  <!-- search input -->
  <div class="ss-search-container" [formGroup]="menuService.gridSearch">
    <input class="ss-search-input" type="text" matInput formControlName="search" [placeholder]="getSearchPlaceholder()" (input)="initSearch()">
    <i><mat-icon [ngClass]="{'with-logo': logoUrl}" *ngIf="isSearchActivated" (click)="resetSearch()">close</mat-icon></i>
  </div>

  <!-- Grid Wrapper -->
  <div class="supersections-grid-container" *ngIf="!isSearchActivated">
    <div *ngFor="let section of superSections" class="supersections-grid-item" (click)="select(section.name)">
      <div class="ss-image-holder">
        <img [defaultImage]="'/assets/images/item-placeholder.png'" [lazyLoad]="section.logo_url"/>
      </div>
      <div class="ss-grid-name">
        {{ section.name }}
      </div>
    </div>
  </div>

  <!-- Search Results Wrapper -->
  <div class="ss-search-output-container" *ngIf="isSearchActivated">

    <!-- Section Results -->
    <div class="ss-search-sections-container" *ngIf="searchSections?.length > 0">
      <div class="ss-items-title">{{ getSearchResultsCopy() }}</div>
      <div class="ss-search-sections">
        <div *ngFor="let section of searchSections" class="supersections-grid-item" (click)="select(section.name)">
          <div class="ss-image-holder">
            <img [defaultImage]="'/assets/images/item-placeholder.png'" [lazyLoad]="section.logo_url"/>
          </div>
          <div class="ss-grid-name">
            {{ section.name }}
          </div>
        </div>
      </div>
    </div>

    <!-- Item Results -->
    <div class="ss-search-items" *ngIf="searchItemsSection?.items?.length > 0">
      <div class="ss-items-title">{{ 'CART.ITEMS' | translate }}</div>
      <app-section [section]="searchItemsSection"></app-section>
    </div>
  </div>

  <!-- Open Cart Button -->
  <div *ngIf="quantity > 0" class="ss-cart-preview" (click)="openCart()" [ngClass]="{'ss-cart-hide': !menuOpened}">
    <div class="cart">
      <img [src]="'cart.svg' | themeSource" alt="cart"/>
      <span class="items">{{quantity}}</span>
    </div>
    <div class="text">
      <span class="order">{{ 'CART.VIEW' | translate }}</span>
    </div>
    <div class="text-price">
      <span class="price">{{ totalPrice | currencySpace }}</span>
    </div>
  </div>
</div>
