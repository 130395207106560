import { TranslateLoader } from '@ngx-translate/core';
import { Observable, of } from 'rxjs';
import * as translationAr from 'i18n/ar.json';
import * as translationDe from 'i18n/de.json';
import * as translationEn from 'i18n/en.json';
import * as translationFa from 'i18n/fa.json';
import * as translationFr from 'i18n/fr.json';
import * as translationRu from 'i18n/ru.json';
import * as translationZh from 'i18n/zh-hans.json';
import * as translationTh from 'i18n/th.json';
import * as translationEl from 'i18n/el.json';
import * as translationCnr from 'i18n/cnr.json';
import * as translationRo from 'i18n/ro.json';
import * as translationBg from 'i18n/bg.json';
import * as translationHu from 'i18n/hu.json';
import * as translationTr from 'i18n/tr.json';
import * as translationEs from 'i18n/es.json';


// eslint-disable-next-line @typescript-eslint/naming-convention
const TRANSLATIONS = {
  ar: translationAr,
  de: translationDe,
  en: translationEn,
  fa: translationFa,
  fr: translationFr,
  ru: translationRu,
  th: translationTh,
  el: translationEl,
  cnr: translationCnr,
  'zh-hans': translationZh,
  ro: translationRo,
  bg: translationBg,
  hu: translationHu,
  tr: translationTr,
  es: translationEs,
};

export class TranslateUniversalLoader implements TranslateLoader {
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  public getTranslation(lang: string): Observable<any> {
    return of(TRANSLATIONS[lang].default);
  }

}
