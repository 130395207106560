/* eslint-disable @typescript-eslint/no-shadow */
import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SuperSectionService } from '../../services/super-section.service';
import { MenuAction, MenuActionObject, Restaurant, VenueType } from '../../services/api.service';
import { DataService } from '../../services/data.service';
import { DialogService } from '../../services/dialog.service';

@Component({
  selector: 'app-bottom-bar',
  templateUrl: './bottom-bar.component.html',
  styleUrls: ['./bottom-bar.component.scss'],
})
export class BottomBarComponent implements OnInit {
  @Input() venue: Restaurant;

  public venueType = VenueType;
  public whatsappLink: string;
  public directoryLink: string;
  public menuActions: MenuActionObject[];
  public hasActions = true;

  constructor(
    private superSectionService: SuperSectionService,
    private dataService: DataService,
    private dialogService: DialogService,
    private router: Router
  ) {
    this.menuActions = this.dataService.getMenuActions();
    if (this.menuActions.length <= 0) {
      this.hasActions = false;
    }
    const ezetapMachineId = this.dataService.getEzetapMachineId();
    if (ezetapMachineId && this.menuActions.length > 0) {
      this.menuActions = this.menuActions.filter((action) => action.action !== MenuAction.Orders && action.action !== MenuAction.Bill);
    }
  }

  public ngOnInit(): void {
    if (this.venue?.directory_url) {
      this.directoryLink = this.venue.directory_url;
    }

    if (this.venue?.phone_number) {
      const phoneNumber: string = this.venue
      .phone_number
      .replace('+', '')
      .replace(' ', '');
      const roomType: string = this.dataService.getTable().table_type;
      const roomNumber: string = this.dataService.getTable().name;
      const message = `Hello, this is ${roomType} ${roomNumber}`;
      this.whatsappLink = encodeURI(`https://wa.me/${phoneNumber}?text=${message}`);
    }
  }

  public goMenu(): void {
    this.superSectionService.openMenu();
  }

  public openWaiterDialog(): void {
    this.dialogService.openWaiter();
  }

  public goDirectory() {
    window.open(this.directoryLink);
  }

  public getActionIcon(action: MenuActionObject) {
    if (action.action !== MenuAction.Custom) {
      return `${action.action}.svg`;
    } else {
      return `${action.config.type}.svg`;
    }
  }

  public clickAction(action: MenuActionObject) {
    switch(action.action) {
      case MenuAction.Waiter:
        this.openWaiterDialog();
        break;
      case MenuAction.Bill:
      case MenuAction.Orders:
        this.router.navigate(['/bill']);
        break;
      case MenuAction.Whatsapp:
        if (this.whatsappLink) {
          window.open(this.whatsappLink);
        }
        break;
      case MenuAction.Directory:
        if (this.directoryLink) {
          this.goDirectory();
        }
        break;
      case MenuAction.Custom:
        window.open(action.config.url || '_self');
    }
  }
}
