<form *ngIf="formGroup" [formGroup]="formGroup" class="bottom dialog" (ngSubmit)="validateVoucher()">
  <p class="title">{{ 'VOUCHER.ADD' | translate }}</p>
  <p *ngIf="this.isPostpaid" class="warning-message">{{ 'VOUCHER.WARNING' | translate }}</p>

  <div class="row">
    <mat-form-field>
      <input matInput type="text" required formControlName="voucherName">
    </mat-form-field>
  </div>

  <div class="buttons">
    <button type="submit" class="confirm" mat-raised-button>{{ 'ACTIONS.SUBMIT' | translate }}</button>
  </div>
</form>
